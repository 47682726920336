// This component is responsible for displaying the status of the files that have been uploaded
import { useAuth0 } from '@auth0/auth0-react';
import { Button, createTableColumn, DataGrid, DataGridBody, DataGridCell, DataGridHeader, DataGridHeaderCell, DataGridRow, DataGridProps, Dialog, DialogSurface, Menu, MenuTrigger, MenuList, MenuItem, MenuPopover, Spinner, TableCellLayout, TableColumnDefinition, Text, Toast, Toaster, ToastTitle, ToastTrigger, useId, useToastController, Link } from '@fluentui/react-components';
import { ArrowDownloadFilled, BezierCurveSquare20Regular, Delete24Regular, DocumentBulletListMultiple24Regular, EmojiSadSlight24Regular, ImageCircle24Regular, LinkSquare24Regular, DocumentPdfRegular, MoreHorizontalRegular, SettingsCogMultipleFilled, ArrowRight24Filled } from '@fluentui/react-icons';
import React, { useEffect, useMemo, useState } from 'react';
import { DataGridItem, deleteSingleFileApi, FileStatusItem, getFileStatusByIndexApi, retrieveFileApi, retryProcessingApi, SelectedFile } from '../../api';
import styles from './FileStatus.module.css';
import DOMPurify from 'dompurify';
import UploadStatus from '../UploadStatus/UploadStatus';

interface Props {
    indexName: string;
    shouldRefresh: boolean; // boolean to trigger a refresh of the file status list = setRefreshGrid
    setSelectedPdf: (pdf: SelectedFile) => void;
    setRefreshGrid: React.Dispatch<React.SetStateAction<boolean>>;
    setNumberOfFilesInSelectedCollection: React.Dispatch<React.SetStateAction<number>>;
    setNumberOfFailedFilesInSelectedCollection: React.Dispatch<React.SetStateAction<number>>;
    autoRefresh: boolean;
    searchFilterText: string;
    isBEadmin: boolean;
    currentPageNum: number;
    setTotalPages: React.Dispatch<React.SetStateAction<number>>;
    //  uploadProgress: Map<string, number>;  // Map with upload progress of each file
}

const FileStatus = ({
    indexName,
    shouldRefresh,
    setSelectedPdf,
    setRefreshGrid,
    setNumberOfFilesInSelectedCollection,
    setNumberOfFailedFilesInSelectedCollection,
    autoRefresh,
    searchFilterText,
    isBEadmin,
    currentPageNum,
    setTotalPages,
    // uploadProgress
}: Props) => {
    const { getAccessTokenSilently } = useAuth0();
    const [fileStatuses, setFileStatuses] = useState<FileStatusItem[]>([]);
    const [failedFileList, setFailedFileList] = useState<DataGridItem[]>([]);
    const [loading, setLoading] = useState(true);
    const [isDeleting, setIsDeleting] = useState(false); // state to track if file is being deleted
    const [searchFilter, setSearchFilter] = useState<string>('');
    const [filter, setFilter] = useState<FileStatusItem[]>([]);
    // const [isClearingFilter, setIsClearingFilter] = useState(false);
    const [isPdfDownloading, setIsPdfDownloading] = useState(false); // show loading while pdf is being downloaded
    const [currentPage, setCurrentPage] = useState(currentPageNum);
    const [sortColumn, setSortColumn] = useState<string>('uploadDate');
    const [sortDirection, setSortDirection] = useState<'ascending' | 'descending'>('descending');

    const itemsPerPage = 25;

    const toasterId = useId("toaster");
    const { dispatchToast } = useToastController(toasterId);
    const notifyError = () =>
        dispatchToast(
            <Toast>
                <ToastTitle
                    action={
                        <ToastTrigger>
                            <Link>Dismiss</Link>
                        </ToastTrigger>
                    }
                >
                    <p>Failed trying to re-process the file. Please delete it and upload again. If you continue to have trouble please contact support.</p>
                </ToastTitle>
            </Toast>,
            { position: "top", intent: "error", timeout: 10000 }
        );
    const notifySuccess = () =>
        dispatchToast(
            <Toast>
                <ToastTitle
                    action={
                        <ToastTrigger>
                            <Link>Dismiss</Link>
                        </ToastTrigger>
                    }
                >
                    <p>File re-processing was successfully started. Please wait a few minutes then try the reload button.</p>
                </ToastTitle>
            </Toast>,
            { position: "top", intent: "success", timeout: 10000 }
        )

    // Define columns for the DataGrid
    const columns: TableColumnDefinition<DataGridItem>[] = [
        // createTableColumn<DataGridItem>({
        //     columnId: "number",
        //     compare: (a, b) => {
        //         return a.number - b.number;
        //     },
        //     renderHeaderCell: () => {
        //         return "#";
        //     },
        //     renderCell: (item) => {
        //         return (
        //             <div className={styles.numberCell}>
        //                 {item.number}
        //             </div>
        //         );
        //     },
        // }),
        createTableColumn<DataGridItem>({
            columnId: "file",
            compare: (a, b) => {
                const result = a.file.label.localeCompare(b.file.label);
                return sortDirection === 'ascending' ? result : -result;
            },
            renderHeaderCell: () => {
                return (
                    <div onClick={() => handleSort('file')} className={styles.columnHeader}>
                        <Text>
                            File {sortColumn === 'file' && (sortDirection === 'ascending' ? '↑' : '↓')}
                        </Text>
                    </div>
                );
            },
            renderCell: (item) => {
                return (
                    <div className={styles.fileCell} >
                        <TableCellLayout media={item.file.icon}>
                            <Text as='span' title={item.documentId}>
                                {item.file.label}
                            </Text>
                        </TableCellLayout>
                    </div>
                );
            },
        }),
        createTableColumn<DataGridItem>({
            columnId: "status",

            renderHeaderCell: () => {
                return (
                    <div className={styles.columnHeader}>
                        Status
                    </div>
                );
            },
            renderCell: (item) => {
                const addToFailList = () => {
                    setFailedFileList(failedFileList => {
                        if (!failedFileList.some(failedItem => failedItem.file.id === item.file.id)) {
                            return [...failedFileList, item];
                        }
                        return failedFileList;
                    });
                };

                return (
                    <div className={styles.cell}>
                        <UploadStatus
                            documentId={item.documentId || ''}
                            index={indexName || ''}
                            fileName={item.file.id || ''}
                            miniDisplay={true}
                            setHasFailed={(failed) => {
                                if (failed) {
                                    addToFailList();
                                    item.hasFailed = true;
                                }
                            }}
                        />
                    </div>
                );
            },
        }),
        createTableColumn<DataGridItem>({
            columnId: "uploadDate",
            compare: (a, b) => {
                const result = a.lastUpdated.label.localeCompare(b.lastUpdated.label);
                return sortDirection === 'ascending' ? result : -result;
            },
            renderHeaderCell: () => {
                return (
                    <div onClick={() => handleSort('uploadDate')} className={styles.columnHeader}>
                        Uploaded {sortColumn === 'uploadDate' && (sortDirection === 'ascending' ? '↑' : '↓')}
                    </div>
                );
            },
            renderCell: (item) => {

                const [year, month, day, hour, minute, second] = item.lastUpdated.label.split('-');
                const formattedTimestamp = `${String(day).padStart(2, '0')}/${String(month).padStart(2, '0')}/${year} - ${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`;

                return (
                    <div>
                        {formattedTimestamp}
                    </div>
                );
            }
        }),
        createTableColumn<DataGridItem>({
            columnId: "actions",
            renderHeaderCell: () => {
                return "";
            },
            renderCell: (item) => {
                const [deleteOpen, setDeleteOpen] = useState(false);
                //handle delete open dialog
                const handleDeleteDialogOpen = () => {
                    if (deleteOpen) {
                        setDeleteOpen(false);
                    } else {
                        setDeleteOpen(true);
                    }
                }
                const [hasFailed, setHasFailed] = useState(false);
                const [retrying, setRetrying] = useState(false);

                // function to retry the upload - will need the documentId and index and filename
                const retryUpload = async () => {
                    setRetrying(true);
                    try {
                        console.log("Starting Retry upload:", item.documentId, item.index_name, item.file.label);
                        const token = await getAccessTokenSilently();
                        const response = await retryProcessingApi(item.index_name, item.documentId, item.file.label, token);
                        console.log("Retry upload response:", response);
                        setRetrying(false);
                        notifySuccess();
                        setRefreshGrid(true);
                    } catch (error) {
                        console.error("Failed to retry upload:", error);
                        // setError(`Failed trying to re-process the file. Please delete and try again. Document ID: ${documentId}, Index: ${index}, File Name: ${fileName}, Error: ${error}`);
                        notifyError();
                        setRetrying(false);
                    }
                };

                // get the individual progress of the file by it's id from the Map. Example: item.file.id
                return (
                    // Confirmation popover
                    <>
                        {/* delete dialog popup */}
                        <Dialog
                            modalType='alert'
                            open={deleteOpen}
                        >
                            <DialogSurface>
                                <div className={styles.deleteDialog}>
                                    <Text
                                        as='span'
                                        size={400}
                                        style={{ color: 'red' }}
                                    >
                                        Delete File: {item.file.label}
                                    </Text>
                                    <br />


                                    <Text>
                                        Are you sure you want to delete this file?<br></br>Note: This will delete the file for everyone.
                                    </Text>
                                    <div className={styles.deleteButtons}>

                                        {isDeleting ? (
                                            <Spinner
                                                label="Deleting..."
                                                size='extra-small'
                                            />
                                        ) : (
                                            <>
                                                <Button
                                                    appearance='primary'
                                                    onClick={() => {
                                                        handleDelete(
                                                            // Order: indexName: string, fileName: string, document_id: string
                                                            indexName,
                                                            item.file.label,
                                                            item.documentId
                                                        );
                                                    }}
                                                >
                                                    Confirm
                                                </Button>
                                                <Button
                                                    appearance='secondary'
                                                    onClick={() => handleDeleteDialogOpen()}
                                                >Cancel</Button>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </DialogSurface>
                        </Dialog>
                        <Menu>
                            <MenuTrigger disableButtonEnhancement>
                                <Button appearance='subtle' icon={<MoreHorizontalRegular />} />
                            </MenuTrigger>

                            <MenuPopover>
                                <MenuList>
                                    <MenuItem disabled={isPdfDownloading || item.file.id.endsWith('.url')} onClick={() => downloadOriginal(indexName, item.documentId, item.file.id)} icon={<ArrowDownloadFilled />}>Download File</MenuItem>
                                    <MenuItem icon={<Delete24Regular />} onClick={() => handleDeleteDialogOpen()}>Delete File</MenuItem>
                                    <MenuItem disabled={!failedFileList.some(failedItem => failedItem.documentId === item.documentId)} icon={<SettingsCogMultipleFilled />} onClick={() => retryUpload()}>{retrying ?
                                        <Spinner
                                            label="Retrying..."
                                            size='extra-tiny'
                                        />
                                        :
                                        "Retry Processing"
                                    }</MenuItem>
                                    {isBEadmin ?

                                        <UploadStatus
                                            documentId={item.documentId || ''}
                                            index={indexName || ''}
                                            fileName={item.file.id || ''}
                                            setHasFailed={setHasFailed}
                                        />
                                        : ''}
                                </MenuList>
                            </MenuPopover>
                        </Menu>


                    </>

                )
            },
        }),
    ];


    // Column sizing options for the DataGrid
    const columnSizingOptions = {
        file: {
            idealWidth: 400,
            minWidth: 200,
            maxWidth: 800,
        },
        status: {
            idealWidth: 150,
            minWidth: 100,
            maxWidth: 300,
        },
        uploadDate: {
            idealWidth: 200,
            minWidth: 150,
            maxWidth: 400,
        },
        actions: {
            idealWidth: 100,
            minWidth: 80,
            maxWidth: 200,
        },
    };



    // Convert list of file status items retrieved from db to list of DataGrid items
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const fileStatusList: FileStatusItem[] = fileStatuses.map((fileStatus: any) => ({
        file: {
            label: fileStatus.file_name,
            icon: fileStatus.file_name.endsWith('.pdf') ? <DocumentPdfRegular /> :
                fileStatus.file_name.endsWith('.url') ? <LinkSquare24Regular /> :
                    fileStatus.file_name.endsWith('.docx') ? <DocumentBulletListMultiple24Regular /> :
                        /\.(jpg|jpeg|png|gif)$/i.test(fileStatus.file_name) ? <ImageCircle24Regular /> :
                            <BezierCurveSquare20Regular />,
            id: fileStatus.file_name,
        },
        documentId: fileStatus.document_id,
        index_name: fileStatus.index_name,
        status: fileStatus.status,
        lastUpdated: {
            label: fileStatus.time,
            timestamp: new Date(fileStatus._ts * 1000) // _ts is a Unix timestamp, so we multiply by 1000 to convert it to JavaScript Date
        },
    }));

    // Fetch file statuses
    const fetchFileStatuses = async (hardRefresh?: boolean) => {
        hardRefresh ? setLoading(true) : '';
        const token = await getAccessTokenSilently();
        const data = await getFileStatusByIndexApi(indexName, token);
        setFileStatuses(data);
        setNumberOfFilesInSelectedCollection(data.length);
        hardRefresh ? setLoading(false) : '';
        hardRefresh ? clearFilter() : '';
        setRefreshGrid(false); // setRefreshGrid to false once the file statuses have been fetched to be triggerd again
        setCurrentPage(1);
    };

    //update the number of failed files
    useEffect(() => {
        if (failedFileList) {
            setNumberOfFailedFilesInSelectedCollection(failedFileList.length);
        }
    }, [failedFileList]);

    // Fetch file statuses on mount and when indexName or autoRefresh changes
    useEffect(() => {
        setFailedFileList([]);

        fetchFileStatuses(true);
        let intervalId: NodeJS.Timeout;
        if (autoRefresh) {
            intervalId = setInterval(() => {
                fetchFileStatuses();
            }, 10000);
        }

        // Cleanup function to clear the interval when autoRefresh is false or when the component unmounts
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [indexName, autoRefresh]);

    // Refresh file statuses when shouldRefresh is true- triggered by UploadArea component
    useEffect(() => {
        if (shouldRefresh) {
            fetchFileStatuses(true);

            // being set to false in the fetchFileStatuses function
        }
    }, [shouldRefresh]);



    // Handle delete file status
    // indexName: string, fileName: string, document_id: string
    const handleDelete = async (indexName: string, fileName: string, document_id: string) => {
        // Check if document_id is defined
        if (!document_id) {
            console.log("Error: document_id is undefined");
            alert("Error: Unable to delete file, document_id is undefined. Please try again.");
            return;
        }
        // Check if indexName is defined
        if (!indexName) {
            console.log("Error: indexName is undefined");
            alert("Error: Unable to delete file, indexName is undefined. Please try again.");
            return;
        }
        // Check if fileName is defined
        if (!fileName) {
            console.log("Error: fileName is undefined");
            alert("Error: Unable to delete file, fileName is undefined. Please try again.");
            return;
        }


        console.log(`Deleting file ${fileName} from index ${indexName}...`);
        setIsDeleting(true);
        const token = await getAccessTokenSilently();

        // Delete single file - will need to pass in index and document_id
        const fileResponse = await deleteSingleFileApi(indexName, document_id, fileName, token);
        console.log("Delete file response at handleDelete: ", fileResponse);
        //  alert("File Deletion started. Please allow a bit of time for it to be completely purged.");
        setIsDeleting(false);
        fetchFileStatuses(true); // refresh file status list
        // set selected pdf to empty
        setSelectedPdf({
            id: '',
            indexName: '',
            document_id: ''
        });// to trigger the clear of the state
    };

    //filter the file list with the search box
    const searchFilterOnChange = (newValue: string) => {
        setSearchFilter(newValue || '');
        const filteredItems = fileStatusList.filter(fileStatusList => fileStatusList.file.label.toLowerCase().includes((newValue || '').toLowerCase()));
        setFilter(filteredItems);
    }

    const clearFilter = () => {
        setSearchFilter('');
        const filteredItems = fileStatusList.filter(fileStatusList => fileStatusList.file.label.toLowerCase().includes(('').toLowerCase()));
        setFilter(filteredItems);
    }

    //listen for search filter changes
    useEffect(() => {
        if (searchFilterText != '') {
            searchFilterOnChange(searchFilterText);
        }
    }
    );

    // Function to handle the download original file - will need index document id and file name
    // filename, index_name, documentId
    const downloadOriginal = async (index_name: string, documentId: string, filename: string,) => {
        setIsPdfDownloading(true);
        try {
            const token = await getAccessTokenSilently(); // get access token

            // index_name, document_id, filename, token
            const fileResponse = await retrieveFileApi(index_name, documentId, filename, token); // get the path to the file
            console.log('File response:', fileResponse)

            // sanatize the before creating the link to avoid XSS issues for the URL
            const sanitizedUrl = DOMPurify.sanitize(new URL(fileResponse).toString());

            //create the download link
            const link = document.createElement('a');
            link.href = sanitizedUrl;
            link.setAttribute(
                'download',
                filename,
            );
            // Append to html link element page
            document.body.appendChild(link);
            // Start download
            link.click();
        } catch (error) {
            console.error('Error downloading file:', error);
        } finally {
            setIsPdfDownloading(false);
        }
    };

    const handleSort = (columnId: string) => {
        if (sortColumn === columnId) {
            setSortDirection(prev => prev === 'ascending' ? 'descending' : 'ascending');
        } else {
            setSortColumn(columnId);
            setSortDirection('ascending');
        }
    };

    // Pagination for the datagrid
    const getCurrentPageItems = () => {
        const filteredItems = searchFilter === ''
            ? fileStatusList.filter((processing) => !processing.status.startsWith('Processing'))
            : filter;

        const sortedItems = [...filteredItems].sort((a, b) => {
            let result = 0;
            switch (sortColumn) {
                case 'file':
                    result = a.file.label.localeCompare(b.file.label);
                    break;
                case 'status':
                    result = a.status.localeCompare(b.status);
                    break;
                case 'uploadDate':
                    result = a.lastUpdated.label.localeCompare(b.lastUpdated.label);
                    break;
                default:
                    result = 0;
            }
            return sortDirection === 'ascending' ? result : -result;
        });

        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return sortedItems.slice(startIndex, endIndex).map((item, index) => ({
            ...item,
            number: startIndex + index + 1
        }));
    };

    const totalPages = useMemo(() => {
        const filteredItems = searchFilter === ''
            ? fileStatusList.filter((processing) => !processing.status.startsWith('Processing'))
            : filter;
        return Math.ceil(filteredItems.length / itemsPerPage);
    }, [fileStatusList, filter, searchFilter, itemsPerPage]);


    useEffect(() => {
        setTotalPages(totalPages);
    }, [totalPages]);

    useEffect(() => {
        setCurrentPage(currentPageNum);
    }, [currentPageNum, fetchFileStatuses])

    return (
        <div className={styles.statusContainer}>
            <Toaster toasterId={toasterId} />

            {/* List file status */}
            <div>
                {/* Display if loading */}
                {loading ? (
                    <Spinner size="large" labelPosition='below' label='Opening Collection' className={styles.loadingSpinner}></Spinner>
                ) : (

                    <div className={styles.listContainer}>

                        {/* Display if no files in the collection */}
                        {fileStatuses.length === 0 ? (
                            <>
                                <Text
                                    as='span'
                                    size={400}
                                    style={{ color: 'var(--primary-color)' }}
                                >
                                    No files here yet. Use the File Upload on the side to get started!
                                </Text>
                                <ArrowRight24Filled style={{ color: 'var(--primary-color)', margin: '20px' }} />
                            </>
                        ) : (
                            <>

                                {/*full list*/}
                                <div className={styles.dataGrid}>
                                    {/* List files in a DataGrid - https://react.fluentui.dev/?path=/docs/components-datagrid--default */}
                                    <DataGrid
                                        items={getCurrentPageItems()}
                                        columns={columns}
                                        sortable={false}
                                        size='small'
                                        // subtleSelection={true}
                                        // selectionAppearance='brand'
                                        getRowId={(item) => item.file.label}
                                        focusMode="cell"
                                        columnSizingOptions={columnSizingOptions}
                                        resizableColumns={true}
                                    // onSelectionChange={(event, data) => {
                                    //     if (data) {
                                    //         // Create an array from the event data object so we can access the values
                                    //         const selectedItemsArray = Array.from(data.selectedItems);
                                    //         if (selectedItemsArray.length > 0) {
                                    //             // We need to add the index name to the filename before setting it as the selected pdf
                                    //             const fileId = selectedItemsArray[0];

                                    //             const selectedItem = fileStatusList.find(fileStatusList => fileStatusList.file.label === fileId);
                                    //             if (selectedItem) {
                                    //                 // Here we set the values from the file that is selected
                                    //                 setSelectedPdf({
                                    //                     id: selectedItem.file.label,
                                    //                     indexName: selectedItem.index_name,
                                    //                     document_id: selectedItem.documentId
                                    //                 });
                                    //             }
                                    //         }
                                    //     }
                                    // }}
                                    >
                                        {/* List Header */}
                                        <DataGridHeader>
                                            <DataGridRow
                                            // Checkbox to select all rows - changed to single select

                                            >
                                                {/* Render headers */}
                                                {({ renderHeaderCell }) => (
                                                    <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                                                )}
                                            </DataGridRow>
                                        </DataGridHeader>
                                        {/* Table */}
                                        <DataGridBody<DataGridItem>>
                                            {({ item, rowId }) => (
                                                <DataGridRow<DataGridItem>
                                                    key={rowId}
                                                // selectionCell={{
                                                //     checkboxIndicator: { "aria-label": "Select row" },
                                                // }}
                                                >
                                                    {({ renderCell }) => (
                                                        <DataGridCell>{renderCell(item)}</DataGridCell>
                                                    )}
                                                </DataGridRow>
                                            )}
                                        </DataGridBody>
                                    </DataGrid>

                                </div>
                            </>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default FileStatus;
// Api functions
import axios, { AxiosProgressEvent } from "axios";
import {
    AskResponse,
    ChatRequest,
    CollectionCategory,
    CollectionName,
    IUploadResponse,
    NewCollectionForm,
    PersonaTemplate,
    Project,
    RenameCollectionResponse,
    TemplateCategory,
    UploadStatusApiResponse
} from "./models";

// Type for Persona Templates
interface Template {
    id: string;
    name: string;
    text: string;
}

// Type
interface IDatabases {
    indexes: string[];
}

// Used to call both chat and langchain endpoints
/**
 * Sends a POST request to a specified endpoint with options and an access token.
 * @param options - An object of type `ChatRequest` containing various parameters for the chat request.
 * @param accessToken - A string representing the Auth0 access token.
 * @returns A promise that resolves to an `AskResponse` object if successful, otherwise throws an error.
 */
export async function chatApi(options: ChatRequest, accessToken: string): Promise<AskResponse> {
    // Check for missing access token
    if (!accessToken) {
        throw new Error("Auth0 access token is missing when calling: [chatApi]");
    }

    // Check for missing options
    if (!options) {
        throw new Error("Options are missing when calling: [chatApi]");
    }

    // Extract the endpoint from the options or use the default value
    const endpoint = options.endpoint || "langchain";

    const response = await fetch(`/${endpoint}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify({
            history: options.history,
            selected_indexes: options.selectedIndexes,
            user: options.user,
            thread_id: options.threadId,
            company_memory: options.companyMemory,
            user_memory: options.userMemory,
            search_only_mode: options.search_only_mode,
            variable_1: options.variableComponentUserInput,
            variable_2: options.variableComponentTwoUserInput,
            overrides: {
                retrieval_mode: options.overrides?.retrievalMode,
                semantic_ranker: options.overrides?.semanticRanker,
                semantic_captions: options.overrides?.semanticCaptions,
                top: options.overrides?.top,
                temperature: options.overrides?.temperature,
                prompt_template: options.overrides?.promptTemplate,
                prompt_template_prefix: options.overrides?.promptTemplatePrefix,
                prompt_template_suffix: options.overrides?.promptTemplateSuffix,
                exclude_category: options.overrides?.excludeCategory,
                disable_follow_up_questions: options.overrides?.disableFollowupQuestions,
                gpt_version: options.overrides?.gpt_version,
                web_results: options.overrides?.webResults,
                web_top: options.overrides?.retrieveWebCount,
                vector_search: options.overrides?.vectorSearch,
                semantic_search: options.overrides?.semanticSearch,
                hybrid_search: options.overrides?.hybridSearch,
                follow_up_prompt: options.overrides?.followUpQuestionPrompt,
                use_gpt4o_mini: options.overrides?.useGpt4oMini,
                prompt_template_name: options.overrides?.promptTemplateName,
                disable_history: options.overrides?.disable_history
            }
        })
    });

    const parsedResponse: AskResponse = await response.json();
    if (!response.ok) {
        throw new Error(parsedResponse.error || "Bad Response from backend at [chatApi]");
    }

    return parsedResponse;
}

// Get Citation File Path - pass in index
/**
 * Constructs a URL path for a citation file based on the provided citation name and path.
 * @param citation The name or identifier of the citation file.
 * @param path The directory path where the citation file is located.
 * @returns A string representing the constructed URL path for the citation file.
 */
export function getCitationFilePath(citation: string, path: string): string {
    // check for missing citation
    if (!citation) {
        return `/content/${path}`;
    }
    // check for missing path
    if (!path) {
        return `/content/${citation}`;
    }

    return `/content/${path}/${citation}`;
}

// Get Citation File Path - multi index
/**
 * Constructs a URL path for a citation file based on the provided citation name.
 * @param citation - The name or identifier of the citation file.
 * @returns The constructed URL path for the citation file.
 */
export function getMultiCitationFilePath(citation: string): string {
    // Check for missing citation
    if (!citation) {
        return `/content/`;
    }

    return `/content/${citation}`;
}

// Upload File
/**
 * Uploads a file or collection of files to a specified index using an access token for authorization.
 * @param request - FormData or NewCollectionForm containing the file(s) to be uploaded.
 * @param indexName - String representing the index to which the file(s) will be uploaded.
 * @param accessToken - String containing the Auth0 access token for authorization.
 * @param onUploadProgress - Optional callback function to handle upload progress events.
 * @returns A Promise that resolves to an IUploadResponse object containing the upload result.
 */
export async function uploadFileApi(
    request: FormData | NewCollectionForm,
    indexName: string,
    accessToken: string,
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
): Promise<IUploadResponse> {
    // Check for missing access token
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [uploadFileApi]");
    }
    // Check for missing index name
    if (!indexName) {
        return Promise.reject("Index name is missing when calling: [uploadFileApi]");
    }
    // Check for missing request
    if (!request) {
        return Promise.reject("Request is missing when calling: [uploadFileApi]");
    }

    // Call the backend endpoint
    try {
        const response = await axios.post(`/upload_file/${indexName}`, request, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            onUploadProgress // Optional callback function to handle upload progress events
        });

        if (response.status !== 200) {
            throw new Error(`Bad Response from backend when Uploading files [uploadFileApi]: ${response.statusText}`);
        }

        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
}

// Upload Status - pass in indexName and documentId
/**
 * Calls the upload status API to retrieve the status of a document upload.
 *
 * This function makes a GET request to the `/upload_status` endpoint with the provided
 * document ID and index name as query parameters. It requires an Auth0 access token for
 * authorization. If the request is successful, it returns the response data as a JSON object.
 * In case of HTTP errors or fetch errors (e.g., network issues), it logs the error and returns null.
 *
 * @param {string} indexName - The name of the index to query.
 * @param {string} documentId - The ID of the document whose upload status is being queried.
 * @param {string} accessToken - The Auth0 access token for authorization.
 * @returns {Promise<Response | null>} A promise that resolves with the response data as a JSON object
 *                                      if the request is successful, or null in case of errors.
 * @throws {Promise<string>} A promise that rejects with an error message if any of the parameters are missing.
 */
export async function uploadStatusApi(indexName: string, documentId: string, accessToken: string): Promise<UploadStatusApiResponse | null> {
    if (!accessToken) {
        // Consider using a custom Error class
        throw new Error("Auth0 access token is missing when calling: [uploadStatusApi]");
    }
    if (!indexName || !documentId) {
        throw new Error(`Missing parameters: ${!indexName ? "Index name" : ""} ${!documentId ? "Document ID" : ""} when calling: [uploadStatusApi]`);
    }

    const queryParams = new URLSearchParams({ documentId, index: indexName }).toString();
    const url = `/upload_status?${queryParams}`;

    try {
        const response = await fetch(url, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: "application/json" // Explicitly accept JSON responses
            }
            // Consider setting a timeout for the request
        });

        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`HTTP error! status: ${response.status}, body: ${errorText}`);
        }

        // Check Content-Type to ensure the response is JSON
        const contentType = response.headers.get("Content-Type");
        if (!contentType || !contentType.includes("application/json")) {
            throw new Error("Received non-JSON response");
        }

        // Set the type to UploadStatusApiResponse
        const data: UploadStatusApiResponse = await response.json();
        return data;
    } catch (error) {
        console.error("Error fetching upload status:", error);
        return null;
    }
}

// Create a new Collection
/**
 * Creates a new collection by sending a POST request to /create_collection.
 *
 * @param formData - The FormData object containing the collection data. (Contains the index name and
 * display name and user tag.)
 * @param accessToken - The access token for authentication.
 * @returns A Promise that resolves to an array of CollectionName objects representing the new collection.
 */
export async function createCollectionApi(formData: FormData, accessToken: string): Promise<IDatabases> {
    // Check for missing access token
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [createIndexApi]");
    }
    // Check for missing form data
    if (!formData) {
        return Promise.reject("Form data is missing when calling: [createIndexApi]");
    }

    // Call the backend endpoint
    try {
        const response = await axios.post(`/create_collection`, formData, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        if (response.status !== 200) {
            throw new Error(`Error creating collection: ${response.statusText}`);
        }

        return response.data;
    } catch (error) {
        throw new Error(`Bad Response from backend at [createIndexApi]: ${error}`);
    }
}

// Rename a Collection
/**
 * Calls the backend to rename an existing collection.
 *
 * This function sends a PUT request to the backend to rename an existing collection. It requires
 * form data containing the new collection details and an Auth0 access token for authentication.
 *
 * @param {FormData} formData - The form data containing the new collection details.
 * @param {string} accessToken - The Auth0 access token for authentication.
 * @returns {Promise<IDatabases>} A promise that resolves to the updated collection details.
 *
 * @throws {Error} Throws an error if the access token or form data is missing, if the backend
 *                 responds with a status other than 200, or if there's a bad response from the backend.
 */
export async function renameCollectionApi(formData: FormData, accessToken: string): Promise<RenameCollectionResponse> {
    // Check for missing access token
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [createIndexApi]");
    }
    // Check for missing form data
    if (!formData) {
        return Promise.reject("Form data is missing when calling: [createIndexApi]");
    }

    // Call the backend /create_collection endpoint
    try {
        const response = await axios.put(`/create_collection`, formData, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        if (response.status !== 200) {
            throw new Error(`Error creating collection: ${response.statusText}`);
        }

        // Assuming the backend now simply returns a success message
        return {
            success: true,
            message: "Collection renamed successfully"
        };
    } catch (error) {
        throw new Error(`Bad Response from backend at [renameCollectionApi]: ${error}`);
    }
}

// Get Collections
/**
 * Queries cosmos db for list instead of Kernel Memory
 *
 * @param accessToken - The access token for authentication.
 * @returns A Promise that resolves to an array of CollectionName objects if successful, or null if there was an error.
 */
export async function getCollectionsApi(accessToken: string): Promise<CollectionName[] | null> {
    // Check for access token
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [getCollectionsApi]");
    }

    // Call the endpoint
    try {
        const response = await fetch("/list_collections", {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        // Check if the fetch was successful
        if (!response.ok) {
            console.error(`Error fetching collections: ${response.status} ${response.statusText}`);
            return null;
        }

        const data = await response.json();
        const collections = data.indexes as CollectionName[];
        return collections;
    } catch (error) {
        // Log the error and return null
        console.error(`Error in [getCollectionsApi]: ${error}`);
        return null;
    }
}

// Function to delete a Collection from file status list
/**
 * Deletes a collection by making a DELETE request.
 *
 * This function sends a DELETE request to delete a specific collection identified by its index name.
 * It requires an Auth0 access token for authentication.
 *
 * @param {string} indexName - The name of the index to be deleted.
 * @param {string} [accessToken] - The Auth0 access token for authentication.
 * @returns {Promise<Response>} A promise that resolves to the fetch response object.
 * @throws {Error} Throws an error if the access token is missing or if the request fails.
 */
export async function deleteCollectionApi(indexName: string, accessToken: string): Promise<Response> {
    if (!accessToken) {
        throw new Error("Auth0 access token is missing when calling: [deleteCollectionApi]");
    }
    if (!indexName) {
        throw new Error("Index name is missing when calling: [deleteCollectionApi]");
    }

    // Call the backend endpoint
    try {
        const response = await fetch(`/delete_collection`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`
            },
            body: JSON.stringify({ index_name: indexName })
        });

        if (!response.ok) {
            throw new Error(`Server responded with a status: ${response.status} ${response.statusText}`);
        }

        return response;
    } catch (error) {
        console.error("Error in deleteCollectionApi:", error);
        throw error;
    }
}

// List templates aka (persona) (gizmo) (quickTool) (tool) etc
/**
 * Retrieves prompt templates from the backend API.
 *
 * @param accessToken - The Auth0 access token for authentication.
 * @returns A Promise that resolves to an array of Persona objects representing prompt templates.
 * @throws Error if the access token is missing or if there is an issue with the HTTP request/response.
 */
export async function getPromptTemplatesApi(accessToken: string): Promise<PersonaTemplate[]> {
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [uploadFileApi]");
    }

    const response = await fetch("/listtemplates", {
        headers: {
            Authorization: `Bearer ${accessToken}` // pass the access token in the header
        }
    });
    // Check that response is ok
    if (!response.ok) {
        throw new Error(`Bad Response from backend at [getPromptTemplatesApi] HTTP error! status: ${response.status}`);
    }
    // check for json
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.includes("application/json")) {
        const parsedResponse = await response.json(); // parse to json
        return parsedResponse.templates;
    } else {
        const textResponse: string = await response.text();
        throw Error(`Bad response at [getPromptTemplatesApi] Invalid content type: ${contentType}\nResponse body is: ${textResponse}`);
    }
}

// Function to retreive a template (tool) by it's ID
/**
 * Fetches a specific template by its ID from the backend API.
 *
 * @param {string} templateId - The ID of the template to retrieve.
 * @param {string} accessToken - The Auth0 access token for authorization.
 * @returns {Promise<PersonaTemplate>} - A promise that resolves to the template data if successful.
 *
 * @throws {Promise<string>} - A promise that rejects with an error message if the request fails or the response is invalid.
 *
 * @example
 * ```
 * getTemplateByIdApi('template123', 'your-access-token')
 *   .then(template => {
 *     console.log('Template data:', template);
 *   })
 *   .catch(error => {
 *     console.error('Error fetching template:', error);
 *   });
 * ```
 */
export async function getTemplateByIdApi(templateId: string, accessToken: string): Promise<PersonaTemplate> {
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [getTemplateByIdApi]");
    }
    if (!templateId) {
        return Promise.reject("Template ID is missing when calling: [getTemplateByIdApi]");
    }

    const response = await fetch(`/templates/${templateId}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });

    // Check for text error
    if (!response.ok) {
        const errorText = await response.text();
        return Promise.reject(`Failed to get template: ${errorText}`);
    }

    try {
        const data = await response.json();
        if (data.template) {
            return data.template;
        } else {
            return Promise.reject("Template key is missing in the response");
        }
    } catch (error) {
        console.error("Error parsing JSON response:", error);
        return Promise.reject("Failed to parse JSON response");
    }
}

// Create a new Persona template
/**
 * Creates a new Persona template by sending a POST request to the server.
 *
 * @param {string} name - The name of the Persona template to be created. Must be a non-empty string.
 * @param {string} [accessToken] - Optional. The access token for authentication.
 * @returns {Promise<Response>} A Promise that resolves to the response of the creation operation.
 * @throws Error if any parameter is invalid or if the request fails.
 */
export async function createTemplateApi(name: string, accessToken: string): Promise<Response> {
    // Check for missing access token
    if (!accessToken) {
        throw new Error("Auth0 access token is missing when calling: [createTemplateApi]");
    }
    // Check for missing name
    if (!name.trim()) {
        throw new Error("Template name is required and cannot be empty when calling: [createTemplateApi].");
    }

    // Wrap the single template in a list
    const templates = [{ name }];

    const response = await fetch(`/templates`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify(templates)
    });

    if (!response.ok) {
        const errorDetails = await response.text(); // Assuming the server returns error details as plain text
        throw new Error(`Creating new template failed [createTemplateApi], ${response.statusText}: ${errorDetails}`);
    }

    return response;
}

// Function to import a list of templates
export async function importTemplateApi(templates: object[], accessToken: string): Promise<Response> {
    if (!accessToken) {
        throw new Error("Auth0 access token is missing when calling: [importTemplateApi]");
    }

    if (!templates || templates.length === 0) {
        throw new Error("Template data is required and cannot be empty when calling: [importTemplateApi].");
    }

    const response = await fetch(`/templates`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify(templates)
    });

    if (!response.ok) {
        const errorDetails = await response.text();
        throw new Error(`Importing templates failed [importTemplateApi], ${response.statusText}: ${errorDetails}`);
    }

    return response;
}

// Update template - pass in form data and id
/**
 * Updates a template by sending a PUT request to the server.
 *
 * @param id - The ID of the template to be updated.
 * @param formData - The FormData object containing the updated template data.
 * @param accessToken - Optional. The access token for authentication.
 * @returns A Promise that resolves to an array of updated Template objects.
 * @throws Error if the access token is missing, the request fails, or parsing the response fails.
 */
export async function updateTemplateApi(id: string, formData: FormData, accessToken: string): Promise<Template[]> {
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [updateTemplate]");
    }
    if (!id) {
        return Promise.reject("Template ID is missing when calling: [updateTemplate]");
    }

    const response = await fetch(`/templates/${id}`, {
        method: "PUT",
        headers: {
            Authorization: `Bearer ${accessToken}`
            //  when doing fetch requests with FormData, you should not set the Content-Type. When you instantiate FormData and use it in a fetch call, the browser automatically sets the Content-Type to multipart/form-data and it also appends a boundary parameter to it which is very important.
            // 'Content-Type':'multipart/form-data'
        },
        body: formData
    });

    // Check if the response status is not "OK".
    if (!response.ok) {
        throw new Error(`Updating template failed: ${response.statusText}`);
    }

    // Parse the response to json
    const jsonResponse = await response.json();

    // Return the parsed data as the result of the Promise.
    return jsonResponse;
}

// Delete template - pass in array of string ids
/**
 * Deletes a template by sending a DELETE request to the server.
 *
 * @param {string} id - The ID of the template to be deleted.
 * @param {string} [accessToken] - Optional. The access token for authentication.
 * @returns {Promise<Response>} A Promise that resolves to the response of the deletion operation.
 * @throws Error if the access token is missing or the request fails.
 */
export async function deleteTemplateApi(id: string, accessToken: string) {
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [deleteTemplateApi]");
    }

    const response = await fetch(`/deletetemplate/${id}`, {
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });
    if (!response.ok) {
        throw new Error(`Error deleting template with id ${id}: ${response.statusText}`);
    }
    return response;
}

// List Chat logs
/**
 * Retrieves the chat logs from the backend API.
 *
 * @param {string} accessToken - The Auth0 access token for authentication.
 * @returns {Promise<string[]>} A Promise that resolves to an array of chat log strings if successful.
 * @throws {Error} If the access token is missing, there is an issue with the HTTP request/response, or the content type is invalid.
 */
export async function getChatLogsApi(accessToken: string): Promise<string[]> {
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [getChatLogsApi]");
    }
    const response = await fetch("/listchatlogs", {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.includes("application/json")) {
        const parsedResponse: string[] = await response.json();
        return parsedResponse;
    } else {
        const textResponse: string = await response.text();
        throw Error("Invalid content type: " + contentType + "\nResponse body: " + textResponse);
    }
}

// List App Logs
/**
 * Retrieves App logs from a backend API using an access token for authentication.
 * @param accessToken - Auth0 access token for authentication.
 * @returns A promise that resolves to an array of App log strings if successful, otherwise throws an error.
 */
export async function getAppLogsApi(accessToken: string): Promise<string[]> {
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [getAppLogs]");
    }

    const response = await fetch("/listapplogs", {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });

    const contentType = response.headers.get("content-type");

    if (contentType && contentType.includes("application/json")) {
        return await response.json();
    } else {
        const textResponse = await response.text();
        throw new Error(`Invalid content type: ${contentType}\nResponse body: ${textResponse}`);
    }
}

// Delete a single file - pass in indexName, documentId, and file
/**
 * Function to delete a single file. Pass a form
 * @param {string} indexName - The name of the index.
 * @param {string} documentId - The ID of the document.
 * @param {string} file - The name of the file to delete.
 * @param {string} [accessToken] - The access token for authentication.
 * @returns {Promise<Response>} A Promise that resolves to the response of the deletion operation.
 */
export async function deleteSingleFileApi(indexName: string, documentId: string, file: string, accessToken: string): Promise<Response> {
    // Check for missing access token
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [deleteSingleFileApi]");
    }
    // Check for missing indexName
    if (!indexName) {
        return Promise.reject("indexName is missing when calling: [deleteSingleFileApi]");
    }
    // Check for missing documentId
    if (!documentId) {
        return Promise.reject("documentId is missing when calling: [deleteSingleFileApi]");
    }
    // Check for missing file
    if (!file) {
        return Promise.reject("file is missing when calling: [deleteSingleFileApi]");
    }

    // 1. Create a new FormData object
    const formData = new FormData();
    // 2. Append the indexName, documentId, and file to the FormData object
    formData.append("index", indexName);
    formData.append("documentId", documentId);
    formData.append("filename", file);

    // Call the backend endpoint
    try {
        const response = await fetch(`/delete_file/`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: formData // 3. Pass our new form here
        });

        return response;
    } catch (error) {
        console.error("Error deleting file:", error);
        throw error;
    }
}

// Function to get total tokens for a user- pass in username
/**
 * Retrieves the total number of tokens for a specified user by making a GET request to the backend API.
 * @param userId - The ID of the user whose total tokens are to be retrieved.
 * @param accessToken - The Auth0 access token for authentication.
 * @returns A promise that resolves to the total number of tokens for the specified user.
 */
export async function getTotalTokensApi(userId: string, accessToken: string): Promise<number> {
    if (!accessToken) {
        throw new Error("Auth0 access token is missing when calling: [getTotalTokensApi]");
    }
    if (!userId) {
        throw new Error("User ID is missing when calling: [getTotalTokensApi]");
    }

    const response = await fetch(`/gettotaltokens/${userId}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`
        }
    });

    const data = await response.json();
    return data.total_tokens;
}

// Function to get tokens this period for a user - pass in username
/**
 * Retrieves the number of tokens used by a specified user since the last reset by making a GET request to the backend API.
 * @param userId - The ID of the user whose token usage is being queried.
 * @param accessToken - The Auth0 access token for authentication.
 * @returns A promise that resolves to the number of tokens used since the last reset for the specified user.
 */
export async function getTokensSinceResetApi(userId: string, accessToken: string): Promise<number> {
    if (!accessToken) {
        throw new Error("Auth0 access token is missing when calling: [getTokensSinceResetApi]");
    }
    if (!userId) {
        throw new Error("User ID is missing when calling: [getTokensSinceResetApi]");
    }

    const response = await fetch(`gettokensusedsincereset/${userId}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`
        }
    });

    const data = await response.json();
    return data.tokens_used_since_reset;
}

// List users info - returns all users with their token and limit info
export async function getUsersInfoApi(accessToken?: string) {
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [getUsersInfoApi]");
    }
    const response = await fetch("/listusers", {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });
    const data = await response.json();
    return data;
}

// Set token limit for user - pass in username and limit
/**
 * Sets a token limit for a specified user by making a GET request to a backend API endpoint.
 * @param {string} userId - The ID of the user whose token limit is to be set.
 * @param {number} limit - The new token limit to be set for the user.
 * @param {string} accessToken - The Auth0 access token for authentication.
 * @returns A promise that resolves to the response of the token limit setting operation.
 */
export async function setTokenLimitApi(userId: string, limit: number, accessToken: string): Promise<Response> {
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [setTokenLimitApi]");
    }
    if (!userId) {
        return Promise.reject("User ID is missing when calling: [setTokenLimitApi]");
    }
    if (!limit) {
        return Promise.reject("Token limit is missing when calling: [setTokenLimitApi]");
    }

    const response = await fetch(`/settokenlimit/${userId}/${limit}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`
        }
    });

    return response;
}

// Reset tokens for user - pass in username
/**
 * Sends a GET request to reset the token count for a specified user.
 * @param {string} userId - The ID of the user whose tokens are to be reset.
 * @param {string} accessToken - The Auth0 access token for authentication.
 * @returns A promise that resolves to the response of the token reset operation.
 */
export async function resetTokensApi(userId: string, accessToken: string): Promise<Response> {
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [resetTokensApi]");
    }
    if (!userId) {
        return Promise.reject("User ID is missing when calling: [resetTokensApi]");
    }

    const response = await fetch(`/resettokens/${userId}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`
        }
    });

    return response;
}

// Get token limit for a user
/**
 * Retrieves the token limit for a specified user by making a GET request to a backend API endpoint.
 * @param userId - The ID of the user whose token limit is to be retrieved.
 * @param accessToken - The Auth0 access token for authentication.
 * @returns A promise that resolves to the token limit for the specified user.
 */
export async function getTokenLimitApi(userId: string, accessToken: string): Promise<number> {
    if (!accessToken) {
        throw new Error("Auth0 access token is missing when calling: [getTokenLimitApi]");
    }
    if (!userId) {
        throw new Error("User ID is missing when calling: [getTokenLimitApi]");
    }

    const response = await fetch(`/gettokenlimit/${userId}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`
        }
    });

    const data = await response.json();
    return data.token_limit;
}

// List docs in originals
export async function getProcessedDocumentsApi(accessToken: string) {
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [getProcessedDocumentsApi]");
    }
    const response = await fetch("/original_documents", {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });
    const data = await response.json();
    return data;
}

// Download processed document - pass in the filename
export async function downloadProcessedDocumentApi(filename: string, accessToken: string) {
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [downloadProcessedDocumentApi]");
    }
    const response = await fetch(`/download_original/${filename}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });
    if (!response.ok) {
        throw new Error(`Error downloading processed document ${filename}: ${response.statusText}`);
    } else {
        return response.blob();
    }
}

// Function to upload icon to /uploadicon | returns the path
/**
 * Uploads an icon file to the server using a POST request with authorization.
 * @param {File} file The icon file to be uploaded.
 * @param {string} accessToken The Auth0 access token for authorization.
 * @returns A promise that resolves to the full path of the uploaded icon if successful.
 */
export async function uploadIconApi(file: File, accessToken: string): Promise<string> {
    if (!accessToken) {
        throw new Error("Auth0 access token is missing when calling: [uploadIconApi]");
    }
    if (!file) {
        throw new Error("File is missing when calling: [uploadIconApi]");
    }

    const formData = new FormData();
    formData.append("file", file);

    const response = await fetch("/uploadicon", {
        method: "POST",
        headers: {
            Authorization: `Bearer ${accessToken}`
        },
        body: formData
    });

    if (!response.ok) {
        throw new Error(`Error uploading icon: ${response.statusText}`);
    }

    const data = await response.json(); // response will be the full path
    return data;
}

// Function to return front end settings, clientID, domain, environment
// DON'T ADD AUTH ON THIS ENDPOINT!
/**
 * Retrieves the frontend settings by making a GET request to the '/settings' endpoint.
 *
 * @returns {Promise<any>} A promise that resolves to an object containing the frontend settings data.
 * @throws {Error} If there is an error in fetching the frontend settings.
 */
export async function getFrontendSettingsApi() {
    try {
        const response = await fetch("/settings");
        if (!response.ok) {
            throw new Error(`Error in [getFrontendSettingsApi] while fetching frontend settings: ${response.statusText}`);
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error("Error in [getFrontendSettingsApi]:", error);
    }
}

// Function to get a list of file status in a collection from db
/** NOT USED
 * Retrieves the file status for a specific index from the backend API.
 *
 * @param {string} indexName - The name of the index for which file status is to be retrieved.
 * @param {string} [accessToken] - Optional. The Auth0 access token for authentication.
 * @returns {Promise<any>} A promise that resolves to the file status data for the specified index.
 * @throws {Error} If the access token is missing or if there is an issue with the HTTP request/response.
 */
export async function getFileStatusByIndexApi(indexName: string, accessToken: string) {
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [getFileStatusByIndexApi]");
    }
    const response = await fetch(`/filestatus/${indexName}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });
    const data = await response.json();
    return data;
}

// Function to delete a file status - pass in indexName and filename
// export async function deleteFileStatusApi(indexName: string, filename: string, accessToken?: string) {
//     if (!accessToken) {
//         return Promise.reject("Auth0 access token is missing when calling: [deleteFileStatusApi]");
//     }
//     const response = await fetch(`/deletefilestatus/${indexName}/${filename}`, {
//         method: "DELETE",
//         headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${accessToken}`
//         }
//     });
//     return response;
// }

// Function to delete all file status in a collection - pass in indexName
// export async function deleteAllFileStatusApi(indexName: string, accessToken?: string) {
//     if (!accessToken) {
//         return Promise.reject("Auth0 access token is missing when calling: [deleteAllFileStatusApi]");
//     }
//     const response = await fetch(`/deleteallfilestatus/${indexName}/`, {
//         method: "DELETE",
//         headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${accessToken}`
//         }
//     });
//     return response;
// }

// // Function to delete all files in a collection - pass in indexName
// export async function deleteAllFilesApi(indexName: string, accessToken?: string) {
//     if (!accessToken) {
//         return Promise.reject("Auth0 access token is missing when calling: [deleteAllFilesApi]");
//     }
//     const response = await fetch(`/deleteall/${indexName}`, {
//         method: "DELETE",
//         headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${accessToken}`
//         }
//     });
//     return response;
// }

// Function to delete a Collection from file status list
/**
 * Sends a DELETE request to remove the file status of a specified collection from cosmos.
 * @param indexName - The name of the index whose file status is to be deleted.
 * @param accessToken - The Auth0 access token for authentication.
 * @returns A promise that resolves to the response of the DELETE request.
 */
export async function deleteCollectionFileStatusApi(indexName: string, accessToken: string): Promise<Response> {
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [deleteCollectionFileStatusApi]");
    }
    if (!indexName) {
        return Promise.reject("Index name is missing when calling: [deleteCollectionFileStatusApi]");
    }

    try {
        const response = await fetch(`/deletefilestatuscollection/${indexName}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`
            }
        });

        if (!response.ok) {
            // Check if the response status is not in the range 200-299
            throw new Error(`Error At [deleteCollectionFileStatusApi] Server responded with a status: ${response.status} ${response.statusText}`);
        }

        return await response.json(); // Parse the response to json
    } catch (error) {
        // Log the error or handle it as needed
        console.error("Error in deleteCollectionFileStatusApi:", error);
        throw error; // Rethrow the error if you want the caller to handle it
    }
}

// Function to get a list of all files in a collection - NOT USED
/**
 * Retrieves a file from the server by making a GET request to the '/download_file' endpoint.
 * This saves the file to memory and returns the url
 * @param {string} index_name - The name of the index from which the file is to be retrieved.
 * @param {string} document_id - The ID of the document containing the file.
 * @param {string} filename - The name of the file to be retrieved.
 * @param {string} accessToken - The Auth0 access token for authentication.
 * @returns {Promise<string>} A promise that resolves to the URL of the retrieved file if successful.
 * @throws {Error} If the access token is missing or if there is an error in retrieving the file.
 */
// Order: index_name, document_id, filename, accessToken
export async function retrieveFileApi(index_name: string, document_id: string, filename: string, accessToken: string) {
    // Check for missing access token
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [retrieveFileApi]");
    }
    // Check for missing index name
    if (!index_name) {
        return Promise.reject("Index name is missing when calling: [retrieveFileApi]");
    }
    // Check for missing document ID
    if (!document_id) {
        return Promise.reject("Document ID is missing when calling: [retrieveFileApi]");
    }
    // Check for missing filename
    if (!filename) {
        return Promise.reject("Filename is missing when calling: [retrieveFileApi]");
    }

    // Construct the URL for the file download
    const url = new URL("/download_file", window.location.origin);
    // Append the query parameters to that URL
    url.searchParams.append("index", index_name);
    url.searchParams.append("documentId", document_id);
    url.searchParams.append("filename", filename);

    // Call the backend endpoint with our URL
    const response = await fetch(url.toString(), {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });

    // Check if the response status is not "OK".
    if (!response.ok) {
        const responseBody = await response.text();
        throw new Error(`Error at[retreiveFileApi] while retrieving file: ${response.status} ${response.statusText}. Response body: ${responseBody}`);
    }
    // response will have raw file data, we need to save it to memory blob in the front end here and return the url to the blob
    const blob = await response.blob(); // load response into memory as blob
    const fileUrl = URL.createObjectURL(blob); // create a URL for the blob
    return fileUrl; // return the url to the blob in memory
}

// Function to get a list of all files in a collection - NOT USED
/**
 * Creates a new support access logs record.
 *
 * This function logs when the client grants or revokes remote access by creating a record in the support access logs.
 * It requires a username, an action (true for granting access, false for revoking), and an Auth0 access token for authentication.
 *
 * @param {string} username - The username for whom the access is being logged.
 * @param {boolean} action - The action being logged, true for granting access, false for revoking.
 * @param {string} [accessToken] - The Auth0 access token for authentication.
 * @returns {Promise<Response>} A promise that resolves to the fetch response object.
 * @throws {Error} Throws an error if any parameter is missing or if the request fails.
 */
export async function setSupportAccessLogsApi(username: string, action: boolean, accessToken: string): Promise<Response> {
    if (!username) {
        return Promise.reject("Username is missing when calling: [setSupportAccessLogsApi]");
    }
    if (action === undefined) {
        return Promise.reject("Action is missing when calling: [setSupportAccessLogsApi]");
    }
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [setSupportAccessLogsApi]");
    }

    try {
        const response = await fetch(`/supportaccesslogs`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`
            },
            body: JSON.stringify({ username, action })
        });

        if (!response.ok) {
            throw new Error(`Server responded with a status: ${response.status} ${response.statusText}`);
        }

        return response;
    } catch (error) {
        console.error("Error in setSupportAccessLogsApi:", error);
        throw error;
    }
}

// Function to get support access logs most recent record
/**
 * Retrieves the most recent record from the support access logs.
 *
 * This function fetches the latest support access log entry. It requires an Auth0 access token for authentication.
 * If the fetch operation is successful, it returns the most recent log entry.
 *
 * @param {string} [accessToken] - The Auth0 access token for authentication.
 * @returns {Promise<any>} A promise that resolves to the most recent support access log entry.
 * @throws {Error} Throws an error if the access token is missing, the request fails, or the server responds with an error status.
 */
export async function getSupportAccessLogsApi(accessToken: string): Promise<Response> {
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [getSupportAccessLogsApi]");
    }

    try {
        const response = await fetch(`/supportaccesslogs`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        if (!response.ok) {
            throw new Error(`Server responded with a status: ${response.status} ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error in getSupportAccessLogsApi:", error);
        throw error;
    }
}

// Function to POST a new maintenance mode log record
/**
 * Creates a new maintenance mode log record.
 *
 * This function logs when the client enables or disables maintenance mode by creating a record in the maintenance mode logs.
 * It requires a username, a status (true for enabling maintenance mode, false for disabling), and an Auth0 access token for authentication.
 *
 * @param {string} username - The username for whom the maintenance mode is being logged.
 * @param {boolean} status - The status being logged, true for enabling maintenance mode, false for disabling.
 * @param {boolean} demo - The status being logged, true for enabling demo mode, false for disabling.
 * @param {string} [accessToken] - The Auth0 access token for authentication.
 * @returns {Promise<Response>} A promise that resolves to the fetch response object.
 * @throws {Error} Throws an error if any parameter is missing or if the request fails.
 */
export async function setMaintenanceModeApi(username: string, status: boolean, demo: boolean, accessToken: string) {
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [setMaintenceModeApi]");
    }
    const response = await fetch(`/maintenancemodelogs`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify({
            username,
            status,
            demo
        })
    });
    return response;
}

// Function to GET support access logs most recent record
/**
 * Retrieves the maintenance mode logs from the backend API.
 *
 * @param {string} accessToken - The Auth0 access token for authentication.
 * @returns {Promise<any>} A promise that resolves to the maintenance mode logs data if successful.
 * @throws {Error} If the access token is missing, there is an issue with the HTTP request/response, or the content type is invalid.
 */
export async function getMaintenanceModeLogsApi(accessToken: string) {
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [getMaintenanceModeLogsApi]");
    }
    try {
        const response = await fetch(`/maintenancemodelogs`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error:", error); // Log any errors
        throw error;
    }
}

// Function to create a new google Analytics record
// No access token on this endpoint!!!
/**
 * Creates a new record for the google analytics settings.
 *
 * This function sends a POST request to the '/google_analytics' endpoint to log the google analytics id.
 *
 * @param {string} googleAnalyticsID - The username of the user who accepted the Terms of Service.
 * @returns {Promise<Response>} A promise that resolves to the response object from the server.
 */
export async function setGoogleAnalyticsApi(googleAnalyticsID: string) {
    const response = await fetch(`/google_analytics`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            googleAnalyticsID
        })
    });
    return response;
}

// Function to get the list of google_analytics records
/**
 * Retrieves the list of google_analytics records from the backend API.
 *
 * This function sends a GET request to the '/google_analytics' endpoint to fetch the list of google analytics id records.
 *
 * @param {string} accessToken - The Auth0 access token for authentication.
 * @returns {Promise<any>} A promise that resolves to the list of TOS acceptance records.
 */
export async function getGoogleAnalyticsApi(accessToken: string) {
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [getTosAcceptanceApi]");
    }
    const response = await fetch(`/google_analytics`, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });
    const data = await response.json();
    return data;
}

/**
 * Creates a new record for the terms and conditions.
 *
 * @param {string} version - The version of the terms and conditions.
 * @param {string} text - The full text of the terms and conditions.
 * @param {string} accessToken - The Auth0 access token for authentication.
 * @returns {Promise<Response>} A promise that resolves to the response object from the server.
 */
export async function setTermsAndConditionsApi(version: string, text: string, accessToken: string) {
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [setTermsAndConditionsApi]");
    }

    const response = await fetch(`/tandc`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify({
            tandc_version: version,
            tandc_text: text
        })
    });
    return response;
}

interface TermsAndConditions {
    version: string;
    text: string;
    timestamp: string | null;
}

/**
 * Retrieves the terms and conditions records from the backend API.
 *
 * @param {string} accessToken - The Auth0 access token for authentication.
 * @returns {Promise<TermsAndConditions>} A promise that resolves to the terms and conditions record.
 */
export async function getTermsAndConditionsApi(accessToken: string): Promise<TermsAndConditions> {
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [getTermsAndConditionsApi]");
    }
    const response = await fetch(`/tandc`, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });
    const data = await response.json();
    return data;
}

// Function to set a new URL string
// No access token on this endpoint!!!
/**
 * Sets a new URL string for the application settings.
 *
 * This function sends a POST request to the '/set_logoURL' endpoint to log the URL string.
 *
 * @param {string} clientLogoID - The URL string to be set.
 * @returns {Promise<Response>} A promise that resolves to the response object from the server.
 */
// export async function setClientLogoUrlApi(clientLogoID: string) {
//     const response = await fetch(`/set_logoURL`, {
//         method: "POST",
//         headers: {
//             "Content-Type": "application/json"
//         },
//         body: JSON.stringify({
//             clientLogoID
//         })
//     });
//     return response;
// }

// // Function to get the list of URL strings
// /**
//  * Retrieves the list of URL strings from the backend API.
//  *
//  * This function sends a GET request to the '/set_logoURL' endpoint to fetch the list of URL strings.
//  *
//  * @param {string} accessToken - The Auth0 access token for authentication.
//  * @returns {Promise<any>} A promise that resolves to the list of URL strings.
//  */
// export async function getClientLogoUrlApi(accessToken: string) {
//     if (!accessToken) {
//         return Promise.reject("Auth0 access token is missing when calling: [getUrlStrings]");
//     }
//     const response = await fetch(`/set_logoURL`, {
//         headers: {
//             Authorization: `Bearer ${accessToken}`
//         }
//     });
//     const data = await response.json();
//     return data;
// }

// Function to retreive the client logo from backend
export async function getClientLogoApi(accessToken: string): Promise<string> {
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [getClientLogoApi]");
    }
    const response = await fetch(`/get_client_logo`, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });
    if (!response.ok) {
        throw new Error(`Error getting client logo: ${response.statusText}`);
    } else {
        const blob = await response.blob();
        return URL.createObjectURL(blob);
    }
}

// Function to upload client logo to backend
export async function uploadClientLogoApi(file: File, accessToken: string): Promise<string> {
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [uploadClientLogoApi]");
    }
    if (!file) {
        return Promise.reject("File is missing when calling: [uploadClientLogoApi]");
    }

    const formData = new FormData();
    formData.append("file", file);

    const response = await fetch("/upload_client_logo", {
        method: "POST",
        headers: {
            Authorization: `Bearer ${accessToken}`
        },
        body: formData
    });

    if (!response.ok) {
        throw new Error(`Error uploading client logo: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
}

// Function to create a new tos_acceptance record - Pass in username in json
// No access token on this endpoint!!!
/**
 * Creates a new record for Terms of Service (TOS) acceptance by a user.
 *
 * This function sends a POST request to the '/tos_acceptance' endpoint to log the acceptance of TOS by a specified user.
 *
 * @param {string} username - The username of the user who accepted the Terms of Service.
 * @param {string} tc_version - The version of the Terms of Service that was accepted.
 *
 * @returns {Promise<Response>} A promise that resolves to the response object from the server.
 */
export async function setTosAcceptanceApi(username: string, tc_version: string) {
    const response = await fetch(`/tos_acceptance`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            username,
            tc_version
        })
    });
    return response;
}

// Function to get the list of tos_acceptance records
/**
 * Retrieves the list of Terms of Service (TOS) acceptance records from the backend API.
 *
 * This function sends a GET request to the '/tos_acceptance' endpoint to fetch the list of TOS acceptance records.
 *
 * @param {string} accessToken - The Auth0 access token for authentication.
 * @returns {Promise<any>} A promise that resolves to the list of TOS acceptance records.
 */
export async function getTosAcceptanceApi(accessToken: string) {
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [getTosAcceptanceApi]");
    }
    const response = await fetch(`/tos_acceptance`, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });
    const data = await response.json();
    return data;
}

// Function to call feedback endpoint. pass in run_id, score and comment are optional
/**
 * Sends feedback to the backend API.
 *
 * This function sends a POST request to the '/feedback' endpoint to submit feedback for a specific run.
 *
 * @param {string} run_id - The ID of the run for which feedback is being submitted.
 * @param {number} [score] - The score given in the feedback.
 * @param {string} [comment] - The comment provided in the feedback.
 * @param {string} accessToken - The Auth0 access token for authentication.
 * @returns {Promise<Response>} A promise that resolves to the response object from the server.
 */
export async function feedbackApi(run_id: string, score?: number, comment?: string, accessToken?: string) {
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [feedbackApi]");
    }
    if (!run_id) {
        return Promise.reject("Run ID is missing when calling: [feedbackApi]");
    }

    const response = await fetch(`/feedback`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify({
            run_id,
            score,
            comment
        })
    });
    return response;
}

// Function to create/update the company_profile record
/**
 * Creates or updates the company profile record by sending a POST request to the server.
 *
 * @param {FormData} formData - The FormData object containing the company profile data.
 * @param {string} accessToken - The Auth0 access token for authentication.
 * @returns {Promise<any>} A promise that resolves to the parsed JSON response from the server.
 * @throws {Error} If the access token is missing or the request fails.
 */
export async function updateCompanyProfileApi(formData: FormData, accessToken: string) {
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [updateCompanyProfileApi]");
    }

    const response = await fetch(`/company_profile`, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${accessToken}`
        },
        body: formData
    });

    if (!response.ok) {
        throw new Error(`Updating company profile failed: ${response.statusText}`);
    }

    // Parse the response to json
    const jsonResponse = await response.json();

    // Return the parsed data as the result of the Promise.
    return jsonResponse;
}

// Function to get the company_profile record
/**
 * Retrieves the company profile data from the backend API.
 *
 * This function sends a GET request to the '/company_profile' endpoint to fetch the company profile information.
 * It requires an Auth0 access token for authentication.
 *
 * @param {string} accessToken - The Auth0 access token for authentication.
 * @returns {Promise<any>} A promise that resolves to the company profile data if successful.
 * @throws {Error} If the access token is missing, there is an issue with the HTTP request/response, or the content type is invalid.
 */
export async function getCompanyProfileApi(accessToken: string) {
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [getCompanyProfileApi]");
    }
    const response = await fetch(`/company_profile`, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });

    // Check if the response status is not "OK".
    if (!response.ok) {
        throw new Error(`Getting company profile failed: ${response.statusText}`);
    }

    // check for json
    const contentType = response.headers.get("content-type");

    if (contentType && contentType.includes("application/json")) {
        const parsedResponse = await response.json();
        return parsedResponse;
    } else {
        const textResponse: string = await response.text();
        throw Error(`Invalid content type: ${contentType}\nResponse body is: ${textResponse}`);
    }
}

// Function to improve the users query- takes a string and returns an improved string
/**
 * Sends a request to improve a given question by making a POST call to the '/improve_question' endpoint.
 *
 * @param {string} question - The question to be improved.
 * @param {string} accessToken - Optional. The Auth0 access token for authentication.
 * @returns {Promise<any>} A promise that resolves to the improved question data.
 * @throws {Error} If the access token is missing, the request fails, or there is an issue with the response.
 */
export async function improveQuestionApi(question: string, accessToken: string) {
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [improveQuestionApi]");
    }
    const response = await fetch(`/improve_question`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify({ query: question }) // changed "question" to "query"
    });
    const data = await response.json();
    return data;
}

// Function to pass an image to the backend and return a SAS url
/**
 * Uploads an image file to the server and retrieves a publicly accessible URL for the uploaded image.
 * @param imageFile - The image file to be uploaded.
 * @param accessToken - The Auth0 access token for authentication.
 * @returns A promise that resolves to the public URL of the uploaded image.
 */
export async function uploadImageAndGetSasUrlApi(imageFile: File, accessToken: string): Promise<string> {
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [uploadImageAndGetSasUrl]");
    }
    if (!imageFile) {
        return Promise.reject("Image file is missing when calling: [uploadImageAndGetSasUrl]");
    }

    const formData = new FormData();
    formData.append("image", imageFile);

    try {
        const response = await fetch("/make_public_image", {
            method: "POST",
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: formData
        });

        if (!response.ok) {
            console.error("Server returned an error when uploading an image", await response.text());
            throw new Error(`Error uploading image: ${response.statusText}`);
        }

        const data = await response.json();
        return data.public_url;
    } catch (error) {
        console.error("An error occurred during image upload:", error);
        throw error;
    }
}

// Upload a new URL with associated index and tags to a collection
/**
 * Uploads a new URL with associated index and tags.
 *
 * This function uploads a new URL to the server along with its index and tags for categorization. It requires an Auth0 access token for authentication.
 * The function validates all input parameters, constructs a request with the URL, index, and tags, and handles any errors during the request or response parsing.
 *
 * @param {string} url - The URL to be uploaded.
 * @param {string} index - The index associated with the URL.
 * @param {string[]} tag - An array of tags associated with the URL.
 * @param {string} accessToken - The Auth0 access token for authentication.
 * @returns {Promise<any>} A promise that resolves to the response from the server.
 * @throws {Error} Throws an error if any parameter is missing, the request fails, or the server responds with an error status.
 */
export async function uploadUrlApi(url: string, index: string, tag: string[], accessToken: string): Promise<Response> {
    // Check for missing access token
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [uploadUrlApi]");
    }

    // Check for missing URL
    if (!url) {
        return Promise.reject("URL is missing when calling: [uploadUrlApi]");
    }

    // Check for missing index
    if (!index) {
        return Promise.reject("Index is missing when calling: [uploadUrlApi]");
    }

    // Check for missing tags - requires user name at least for tag
    if (!tag) {
        return Promise.reject("Tag is missing when calling: [uploadUrlApi]");
    }

    try {
        const queryParams = new URLSearchParams({
            file: url,
            index: index,
            tags: tag.join(",") // Assuming tags are sent as a comma-separated string
        });

        const response = await fetch(`/upload_url?${queryParams.toString()}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`
            }
        });

        if (!response.ok) {
            throw new Error(`Server responded with a status: ${response.status} ${response.statusText}`);
        }

        return await response.json();
    } catch (error) {
        console.error("Error in uploadUrlApi:", error);
        throw error;
    }
}

// Function to create a new project record
/**
 * Creates a new project record.
 *
 * This function sends a POST request to the '/projects' endpoint to create a new project record with the provided details.
 * @param {string} project_id - A unique identifier for the project.
 * @param {string} project_name - The name of the project.
 * @param {string} project_description - A description of the project.
 * @param {string} owner - The owner of the project.
 * @param {string} project_tool_name - The tool name associated with the project.
 * @param {string} project_tool_id - The tool id associated with the project.
 * @param {string} project_collections - An array of collections associated with the project.
 * @param {string} last_updated - The timestamp of the last update.
 * @param {string} prompts - An array of the prompts asked.
 * @param {string[]} results - An array of the results from prompts.
 * @param {string} variableInputs - an array of the variable inputs.
 *
 * @returns {Promise<Response>} A promise that resolves to the response object from the server.
 */
export async function createProjectRecordApi(
    project_id: string,
    project_name: string,
    project_description: string,
    owner: string,
    project_tool_name: string,
    project_tool_id: string,
    project_collections: string,
    last_updated: string,
    prompts: string,
    results: string[],
    variableInputs: string,
    accessToken: string
) {
    // Check for missing access token
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [uploadUrlApi]");
    }

    const response = await fetch(`/projects`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify({
            id: project_id,  // Add id to the request body
            project_name,
            project_description,
            owner,
            project_tool_name,
            project_tool_id,
            project_collections,
            last_updated,
            prompts,
            results,
            variableInputs
        })
    });
    if (!response.ok) {
        throw new Error(`Failed to create project: ${response.statusText}`);
    }
    const data = await response.json();
    console.log("Response from createProjectRecordApi:", typeof(data), data);
    return data.success.id || '';
}

/**
 * Project  - Updates a specific field of an existing project record.
 *
 * This function sends a POST request to the '/projects' endpoint to update a specific field of an existing project record with the provided value.
 * If no new value is provided, the request is not sent.
 * @param {string} id - A unique identifier for the project.
 * @param {keyof Project} field - The name of the field to update.
 * @param {string} value - The new value to set for the specified field.
 *
 * @returns {Promise<Response | null>} A promise that resolves to the response object from the server or null if no new value is provided.
 */
export async function updateProjectFieldApi(id: string, field: keyof Project, value: string, accessToken: string): Promise<Response | null> {
    // Check if the new value is provided
    if (value === undefined || value === null) {
        console.log("No new value provided to update.");
        return null;
    }
    // Check for missing access token
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [uploadUrlApi]");
    }

    const dataToUpdate = {
        id,
        [field]: value
    };

    try {
        const response = await fetch(`/projects`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`
            },
            body: JSON.stringify(dataToUpdate)
        });

        if (!response.ok) {
            // Log the response status and text for debugging
            const errorText = await response.text();
            console.error(`Server error: ${response.status} - ${errorText}`);
            return null;
        }

        return response;
    } catch (error) {
        console.error("Error making request:", error);
        return null;
    }
}

//Update all fields in one go
export async function updateProjectFieldsApi(id: string, fields: Partial<Project>, accessToken: string): Promise<Response | null> {
    // Check if fields to update are provided
    if (!fields || Object.keys(fields).length === 0) {
        console.log("No fields provided to update.");
        return null;
    }

    // Check for missing access token
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [updateProjectFieldsApi]");
    }

    const dataToUpdate = {
        id,
        ...fields
    };

    try {
        const response = await fetch(`/projects`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`
            },
            body: JSON.stringify(dataToUpdate)
        });

        if (!response.ok) {
            // Log the response status and text for debugging
            const errorText = await response.text();
            console.error(`Server error: ${response.status} - ${errorText}`);
            return null;
        }

        return response;
    } catch (error) {
        console.error("Error making request:", error);
        return null;
    }
}

/**
 * Project - Deletes a specific project record by its ID.
 *
 * This function sends a DELETE request to the '/projects' endpoint to delete a project record with the provided ID.
 * @param {string} project_id - The ID of the project to delete.
 *
 * @returns {Promise<Response>} A promise that resolves to the response object from the server.
 */
export async function deleteProjectRecordApi(project_id: string, accessToken: string): Promise<Response> {
    // Check if the ID is provided
    if (!project_id) {
        throw new Error("Project ID is required");
    }
    // Check for missing access token
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [uploadUrlApi]");
    }

    const response = await fetch("/projects", {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify({ id: project_id })
    });

    if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error || "Failed to delete record");
    }

    return response;
}

// Get Collections
/**
 * Queries cosmos db for list instead of Kernel Memory
 *
 * @param accessToken - The access token for authentication.
 * @returns A Promise that resolves to an array of CollectionName objects if successful, or null if there was an error.
 */
export async function getProjectRecordsApi(accessToken: string): Promise<Project[] | null> {
    // Check for access token
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [getCollectionsApi]");
    }

    // Call the endpoint
    try {
        const response = await fetch("/get_projects", {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        // Check if the fetch was successful
        if (!response.ok) {
            console.error(`Error fetching project records: ${response.status} ${response.statusText}`);
            return null;
        }

        const data = await response.json();
        return data;
    } catch (error) {
        // Log the error and return null
        console.error(`Error in [getProjectRecords]: ${error}`);
        return null;
    }
}

// Get all template_categories
export async function getAllTemplateCategoriesApi(accessToken: string): Promise<TemplateCategory[]> {
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [getAllTemplateCategoriesApi]");
    }
    const response = await fetch("/template_categories", {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });
    const data = await response.json();
    return data;
}

// Create a new template category
export async function createTemplateCategoryApi(
    newCategoryName: string,
    newCategoryDescription: string,
    newCategoryColor: string,
    accessToken: string
): Promise<Response> {
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [createTemplateCategoryApi]");
    }
    if (!newCategoryName) {
        return Promise.reject("Category name is missing when calling: [createTemplateCategoryApi]");
    }
    if (!newCategoryDescription) {
        return Promise.reject("Category description is missing when calling: [createTemplateCategoryApi]");
    }

    const response = await fetch("/template_categories", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify({ category_name: newCategoryName, description: newCategoryDescription, color: newCategoryColor })
    });
    return response;
}

// Edit / Update an existing template category
export async function updateTemplateCategoryApi(id: string, categoryName: string, description: string, color: string, accessToken: string): Promise<Response> {
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [updateTemplateCategoryApi]");
    }
    if (!id) {
        return Promise.reject("Category ID is missing when calling: [updateTemplateCategoryApi]");
    }
    if (!categoryName) {
        return Promise.reject("Category name is missing when calling: [updateTemplateCategoryApi]");
    }
    if (!description) {
        return Promise.reject("Category description is missing when calling: [updateTemplateCategoryApi]");
    }

    const response = await fetch("/template_categories", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify({ id, category_name: categoryName, description, color })
    });

    if (!response.ok) {
        const errorText = await response.text();
        return Promise.reject(`Failed to update category: ${errorText}`);
    }

    return response;
}

// Delete a template category
export async function deleteTemplateCategoryApi(id: string, accessToken: string): Promise<Response> {
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [deleteTemplateCategoryApi]");
    }
    if (!id) {
        return Promise.reject("Category ID is missing when calling: [deleteTemplateCategoryApi]");
    }

    const response = await fetch(`/template_categories/${id}`, {
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });

    if (!response.ok) {
        const errorText = await response.text();
        return Promise.reject(`Failed to delete category: ${errorText}`);
    }

    return response;
}

interface CollectionCategoryRequestBody {
    category_name: string;
    description: string;
    color?: string;
}

// Create a new Collection Category record
export async function createCollectionCategoryApi(
    newCategoryName: string,
    newCategoryDescription: string,
    newCategoryColor: string | null,
    accessToken: string
): Promise<Response> {
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [createCollectionCategoryApi]");
    }
    if (!newCategoryName) {
        return Promise.reject("Category name is missing when calling: [createCollectionCategoryApi]");
    }
    if (!newCategoryDescription) {
        return Promise.reject("Category description is missing when calling: [createCollectionCategoryApi]");
    }

    const requestBody: CollectionCategoryRequestBody = {
        category_name: newCategoryName,
        description: newCategoryDescription
    };

    if (newCategoryColor) {
        requestBody.color = newCategoryColor;
    }

    const response = await fetch("/collection_categories", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify(requestBody)
    });
    return response;
}

// GET all Collection Categories
export async function getAllCollectionCategoriesApi(accessToken: string): Promise<CollectionCategory[]> {
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [getAllCollectionCategoriesApi]");
    }
    const response = await fetch("/collection_categories", {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });
    const data = await response.json();
    return data;
}

// GET Collections with External Connections
/**
 * Fetches the list of external collections from the API.
 *
 * @param {string} accessToken - The Auth0 access token required for authorization.
 * @returns {Promise<any>} A promise that resolves to the data fetched from the API.
 * @throws A promise rejection with an error message if the access token is missing.
 */
export async function getExternalCollectionsApi(accessToken: string) {
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [getExternalCollectionsApi]");
    }
    const response = await fetch("/list_collections_external", {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });
    const data = await response.json();
    return data;
}

// Collection Category - Delete a Collection Category by it's ID
export async function deleteCollectionCategoryApi(id: string, accessToken: string): Promise<Response> {
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [deleteCollectionCategoryApi]");
    }
    if (!id) {
        return Promise.reject("Category ID is missing when calling: [deleteCollectionCategoryApi]");
    }

    const response = await fetch(`/collection_categories/${id}`, {
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });

    if (!response.ok) {
        const errorText = await response.text();
        return Promise.reject(`Failed to delete category: ${errorText}`);
    }

    return response;
}

// Indexer - GET indexer file status by collection name
export async function getIndexerFileStatusApi(accessToken: string, collection_name: string) {
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [getIndexerFileStatusApi]");
    }
    if (!collection_name) {
        return Promise.reject("Collection name is missing when calling: [getIndexerFileStatusApi]");
    }

    const response = await fetch(`/indexer_file_status/${collection_name}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });

    const data = await response.json();
    return data;
}

//  Indexer - Function to delete all indexer file status in a collection - pass in indexName
export async function deleteAllIndexerFileStatusApi(indexName: string, accessToken?: string) {
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [deleteAllIndexerFileStatusApi]");
    }
    if (!indexName) {
        return Promise.reject("Index name is missing when calling: [deleteAllIndexerFileStatusApi]");
    }
    const response = await fetch(`/delete_all_indexer_file_status/${indexName}`, {
        // Removed trailing slash
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`
        }
    });
    return response;
}

//  Indexer - Seed a collection
export async function seedCollectionApi(formData: FormData, accessToken?: string) {
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [seedCollectionApi]");
    }
    if (!formData) {
        return Promise.reject("Form data is missing when calling: [seedCollectionApi]");
    }

    try {
        const response = await fetch(`/trigger_indexer`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: formData
        });

        const data = await response.json();
        return data;
    } catch (error) {
        return Promise.reject(`Error in [seedCollectionApi]: ${error}`);
    }
}

//  Indexer - Scan for changes to the files and update indexer
export async function checkForChangesApi(folder_id: string, drive_id: string, accessToken?: string) {
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [checkForChangesApi]");
    }

    if (!folder_id) {
        return Promise.reject("Missing folder_id when calling: [checkForChangesApi]");
    }

    if (!drive_id) {
        return Promise.reject("Missing drive_id when calling: [checkForChangesApi]");
    }

    try {
        const url = new URL(`/scan_for_changes`, window.location.origin);
        url.searchParams.append("folder_id", folder_id);
        url.searchParams.append("drive_id", drive_id);

        const response = await fetch(url.toString(), {
            method: "GET",
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        if (!response.ok) {
            throw new Error(`Error in [checkForChangesApi]: ${response.statusText}`);
        }

        const data = await response.json();
        // Return the data as an object
        return {
            totalItems: data.total_items,
            newItemsCount: data.new_items_count,
            deletedItemsCount: data.deleted_items_count,
            modifiedItemsCount: data.modified_items_count
        };
    } catch (error) {
        if (error instanceof Error) {
            return Promise.reject(`Error in [checkForChangesApi]: ${error.message}`);
        } else {
            return Promise.reject("An unknown error occurred in [checkForChangesApi]");
        }
    }
}

//  Indexer - Function to apply the indexer changes
export async function applyIndexerChangesApi(index_name: string, accessToken: string) {
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [applyChangesApi]");
    }
    if (!index_name) {
        return Promise.reject("Index name is missing when calling: [applyChangesApi]");
    }

    try {
        const response = await fetch(`/process_changes/${index_name}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        if (!response.ok) {
            throw new Error(`Error in [applyChangesApi]: ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        return Promise.reject(`Error in [applyChangesApi]: ${error}`);
    }
}

// function to retry processing of a file
export async function retryProcessingApi(index_name: string, document_id: string, file_name: string, accessToken: string) {
    if (!accessToken) {
        return Promise.reject("Auth0 access token is missing when calling: [retryProcessingApi]");
    }
    if (!index_name) {
        return Promise.reject("Index name is missing when calling: [retryProcessingApi]");
    }
    if (!document_id) {
        return Promise.reject("Document ID is missing when calling: [retryProcessingApi]");
    }
    if (!file_name) {
        return Promise.reject("File name is missing when calling: [retryProcessingApi]");
    }

    try {
        const response = await fetch(`/retry_processing`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`
            },
            body: JSON.stringify({ index_name, document_id, file_name })
        });

        if (!response.ok) {
            throw new Error(`Error in [retryProcessingApi]: ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        return Promise.reject(`Error in [retryProcessingApi]: ${error}`);
    }
}

// Landing page - images are in public/images

import { Warning24Regular } from "@fluentui/react-icons";
import React from 'react';
import LoginButton from '../../components/LogInButton/LogInButton';
import styles from './landingPage.module.css';
import { PageFooter } from "../../components/PageFooter/PageFooter";


const LandingPage: React.FC = () => {
    const queryParameters = new URLSearchParams(window.location.search);
    const authMessage = queryParameters.get("message");

    return (
        <div className={styles.containerLanding}>

            <section>
                <img src="/images/BEAILogoFinal.png" width="350px" />

                {authMessage ? (
                    <>
                        <div className={styles.messageDialog}>
                            <Warning24Regular />
                            <p>{authMessage}</p>
                            <p>If you require further assistance, please contact <a href="mailto:support@blue-edge.ai">support@blue-edge.ai</a></p>
                        </div>
                    </>
                ) : ('')}

                <menu>
                    <div className={styles.linksContainer}>
                        <LoginButton className={styles.loginButton} />
                    </div>
                </menu>
            </section>

            <PageFooter />
        </div>
    )
}

export default LandingPage
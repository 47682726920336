
import * as React from 'react';
import { createContext } from '@fluentui/react-context-selector';

// Create a default context value
const defaultContextValue = { dir: 'ltr' };

// Create the context with a default value
export const IconDirectionContext = createContext(defaultContextValue);

interface FluentUIWrapperProps {
    children: React.ReactNode;
}

export const FluentUIWrapper: React.FC<FluentUIWrapperProps> = ({ children }) => {
    return (
        <IconDirectionContext.Provider value={defaultContextValue}>
            {children}
        </IconDirectionContext.Provider>
    );
};
import styles from "./UserChatMessage.module.css";
import { Chat24Regular } from '@fluentui/react-icons';
interface Props {
    message: string;
}


export const UserChatMessage = ({ message }: Props) => {
    return (
        <div className={styles.container}>

            <div className={styles.message}>
                <div className={styles.personaIndicator}>
                    <Chat24Regular />
                </div>
                {message}</div>
        </div >
    );
};

import { renderToStaticMarkup } from "react-dom/server";
import { CollectionName } from "../../api";
// import { getCitationFilePath } from "../../api";

type HtmlParsedAnswer = {
    answerHtml: string;
    citations: string[];
    followupQuestions: string[];
    selectedIndex: CollectionName[];
};

// convert jsx to string
export function parseAnswerToHtml(
    answer: string,
    //  onCitationClicked: (citationFilePath: string, selectedIndex: string) => void,
    selectedIndex: CollectionName[]
): HtmlParsedAnswer {
    const citations: string[] = [];
    const followupQuestions: string[] = [];

    // Extract any follow-up questions that might be in the answer - regex matches any text between << and >>
    let parsedAnswer = answer.replace(/<<([^>>]+)>>/g, (match, content) => {
        followupQuestions.push(content);
        return "";
    });

    // trim any whitespace from the end of the answer after removing follow-up questions
    parsedAnswer = parsedAnswer.trim();

    let parts: string[] = [];
    try {
        // Look for citations enclosed in square brackets
        parts = parsedAnswer.split(/\[([^\]]+)\]/g);
    } catch (error) {
        console.error("Error splitting parsedAnswer:", error);
        return {
            answerHtml: parsedAnswer,
            citations,
            followupQuestions,
            selectedIndex,
        };
    }

    // insert the superscript citation numbers
    const fragments: string[] = parts.map((part, index) => {
        if (index % 2 === 0) {
            return part;
        } else {
            let citationIndex: number;
            if (citations.indexOf(part) !== -1) {
                citationIndex = citations.indexOf(part) + 1;
            } else {
                citations.push(part);
                citationIndex = citations.length;
            }
            // this maps the citation to the correct file path
            // const path = getCitationFilePath(part, selectedIndex);
            // superscript - this is the citation number that appears in the text - superscript renders at half line height
            return renderToStaticMarkup(
                // changed from link "a" to "span" (LF: REMOVED THE LINK)
                // <span
                //     className="supContainer" title={part} onClick={() => onCitationClicked(path, selectedIndex)}>
                //     <sup>{citationIndex}</sup>
                // </span>
                <span
                    className="supContainer" title={part}>
                    <sup>{citationIndex}</sup>
                </span>
            );
        }
    });

    return {
        answerHtml: fragments.join(""),
        citations,
        followupQuestions,
        selectedIndex,
    };
}
// This will hold the utility functions needed for the frontend

// This function will take in the name of a index and convert it to ASCII then convert to string
// requirements of index name from microsoft: Index must start and end with alphanumeric characters and contain only lowercase letters, digits or dashes. - max 128 characters
// Blob container name max 1024 characters
// encode - string input to ascii
export const encodeIndexName = (indexName: string): string => {
    let encodedName = "";
    for (let i = 0; i < indexName.length; i++) {
        encodedName += indexName.charCodeAt(i) + "-";
    }
    return encodedName.slice(0, -1); // remove trailing dash
};

// decode
export const decodeIndexName = (encodedIndexName: string): string => {
    const asciiValues = encodedIndexName.split("-");
    let decodedName = "";
    for (let i = 0; i < asciiValues.length; i++) {
        if (asciiValues[i] !== "") {
            decodedName += String.fromCharCode(parseInt(asciiValues[i]));
        }
    }
    return decodedName;
};

// convert hex to rga for opacity in persona selector
export function hexToRGBA(hex: string, opacity: number) {
    const r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

// Function to convert seconds into days, hours, minutes, and seconds
export function formatTime(seconds: number) {
    const days = Math.floor(seconds / (24 * 60 * 60));
    seconds -= days * 24 * 60 * 60;
    const hours = Math.floor(seconds / (60 * 60));
    seconds -= hours * 60 * 60;
    const minutes = Math.floor(seconds / 60);
    seconds -= minutes * 60;

    return `${days} days, ${hours} hours, ${minutes} minutes, and ${Math.floor(seconds)} seconds`;
}

// Function to convert memory usage from bytes to gigs with two decimal
export function formatMemory(bytes: number) {
    return (bytes / 1024 ** 3).toFixed(2);
}

// Function to convert bytes to Megabytes for network stats
export function formatNetwork(bytes: number) {
    return (bytes / 1024 ** 2).toFixed(2); // Convert to Megabytes
}
import { BrandVariants, Theme, createDarkTheme, createLightTheme } from "@fluentui/react-components";

// Set the brand colors default is 80
export const blueedgeai: BrandVariants = {
    10: "#020404",
    20: "#101B1C",
    30: "#152D2F",
    40: "#183A3D",
    50: "#1B484B",
    60: "#1C565A",
    70: "#1D656A",
    80: "#00b0ba",
    90: "#1C838A",
    100: "#00b0ba",
    110: "#13A3AB",
    120: "#09B3BC",
    130: "#38C2CB",
    140: "#6DCED4",
    150: "#94DADF",
    160: "#B7E6E9",
};

// use those colors to create the Light Theme
export const lightTheme: Theme = {
    ...createLightTheme(blueedgeai)
};

// create a dark theme
export const darkTheme: Theme = {
    ...createDarkTheme(blueedgeai)
}

// Set custom 
lightTheme.colorBrandBackground = blueedgeai[90];
lightTheme.colorBrandForeground1 = blueedgeai[100]; //text

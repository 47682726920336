/* eslint-disable @typescript-eslint/no-explicit-any */
// This is the template manager component in the dashboard.
// It will create new - edit and delete templates from cosmos db
import { useAuth0 } from '@auth0/auth0-react';
import { DefaultButton, Dialog, DialogContent, DialogFooter, Dropdown, IDropdownOption, IDropdownProps, Label, Panel, PanelType, PrimaryButton, Spinner, SwatchColorPicker, TextField } from '@fluentui/react';
import { Button, Divider, Field, Image, Input, Radio, RadioGroup, RadioGroupOnChangeData, Switch, Text, Textarea, Toast, ToastBody, Toaster, ToastIntent, ToastTitle, useId, useToastController } from '@fluentui/react-components';
import { Add24Filled, ArrowCircleLeft32Filled, Delete24Regular, Icons24Regular, Save24Regular, ToolboxFilled } from '@fluentui/react-icons';
import React, { act, FormEvent, useEffect, useState } from 'react';

import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { CustomJwtPayload, PersonaTemplate, TemplateCategory } from '../../api';
import { createTemplateApi, createTemplateCategoryApi, deleteTemplateApi, deleteTemplateCategoryApi, getAllTemplateCategoriesApi, getPromptTemplatesApi, updateTemplateApi, updateTemplateCategoryApi } from '../../api/api';
import ClientLogoTransparent from "../../assets/LogoTransparent.png";
import ExportTemplate from '../../components/ExportTemplate/ExportTemplate';
import ImportTemplate from '../../components/ImportTemplate/ImportTemplate';
import { MaintenanceMode } from "../../components/MaintenanceMode";
import { ManagementToolbar } from '../../components/ManagmentToolbar';
import PersonaIcon from '../../components/PersonaIcon/PersonaIcon';
import UserProfile from '../../components/UserProfile/UserProfile';
import styles from './PersonaManager.module.css';




const PersonaManager: React.FC = () => {
  const navigate = useNavigate();
  const { isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();
  const [isAuthorizedToViewPersonaManager, setIsAuthorizedToViewPersonaManager] = useState(false);
  const [isAuthorizedToAdd, setIsAuthorizedToAdd] = useState(false);
  const [isAuthorizedToDelete, setIsAuthorizedToDelete] = useState(false);
  const [activeTemplate, setActiveTemplate] = useState<PersonaTemplate | null>(null);
  const [templatesList, setTemplatesList] = useState<PersonaTemplate[]>([])
  const [newTemplateName, setNewTemplateName] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(false) // loading indicator while personas load
  const [isSaving, setIsSaving] = useState<boolean>(false);// saving indicator
  const [iconFilename] = useState<string | null>(null);
  const [editorData, setEditorData] = useState(''); // state to hold quill state for markdown editor
  const [dialogVisible, setDialogVisible] = React.useState(false); // Track if the dialog is visible
  const [deleteDialogVisible, setDeleteDialogVisible] = React.useState(false);
  const [confirmInput, setConfirmInput] = useState(''); // state to track text input in confirmation popover
  const [message, setMessage] = useState(''); // Track the success/fail message
  const [isDeleting, setIsDeleting] = useState(false); // track if persona is deleting
  const [dropdownOptions, setDropdownOptions] = useState<IDropdownOption[]>([]);
  const [allTemplateCategories, setAllTemplateCategories] = useState<TemplateCategory[]>([]);
  const [selectedTemplateCategory, setSelectedCategory] = useState<TemplateCategory>();
  const [isAddNewCategoryPopoverOpen, setIsAddNewCategoryPopoverOpen] = useState(false);
  const [isEditCategoryPopoverOpen, setIsEditCategoryPopoverOpen] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState<string>('');
  const [newCategoryDescription, setNewCategoryDescription] = useState<string>('');
  const [newCategoryColor, setNewCategoryColor] = useState<string>('#000000');
  const [editCategoryName, setEditCategoryName] = useState<string>(''); // the name of the category being edited
  const [editCategoryDescription, setEditCategoryDescription] = useState<string>(''); // the description
  const [editCategoryId, setEditCategoryId] = useState<string>(''); // the id of the category being edited
  const [editCategoryColor, setEditCategoryColor] = useState<string>('#000000'); // the color of the category being edited
  const [inputError, setInputError] = useState<boolean>(false);
  const [enableVariableOne, setEnableVariableOne] = useState<boolean>(false);
  const [enableVariableTwo, setEnableVariableTwo] = useState<boolean>(false);
  const [enableCustomFixedQueryText, setEnableFixedQueryText] = useState<boolean>(false);
  const [disableDocumentCollections, setDisableDocumentCollections] = useState<boolean>(true);
  const [disableSubmitQuery, setDisableSubmitQuery] = useState<boolean>(true);
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);

  // Define a notify to display the Toast messages
  const notify = (
    title: string = 'Success!', // default
    body: string = 'The operation was successful.',
    message_intent: ToastIntent = "success"
  ) =>
    dispatchToast(
      // Use the Toast component to display a message
      <Toast
        as='div'
        appearance='inverted' // dark mode
        key={undefined}
      >
        <ToastTitle>{title}</ToastTitle>
        <ToastBody><Text>{body}</Text></ToastBody>
      </Toast>,
      { intent: message_intent }
    );


  // Check auth
  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated, navigate]);

  // get fresh access token and check roles
  useEffect(() => {
    const checkRole = async () => {
      try {
        const token = await getAccessTokenSilently();
        const decodedToken = jwtDecode<CustomJwtPayload>(token);
        const permissions = decodedToken.permissions;
        if (permissions.includes('view:Dashboard-Users')) {
          setIsAuthorizedToViewPersonaManager(true);
        }
        if (permissions.includes('add:Templates')) {
          setIsAuthorizedToAdd(true);
        }
        if (permissions.includes('delete:Templates')) {
          setIsAuthorizedToDelete(true);
        }
        else {
          setIsAuthorizedToViewPersonaManager(false);
          alert("You are not authorized to view this page");
          navigate("/");
        }
      } catch (e) {
        // Handle errors - e.g. token is invalid, expired, etc.
        console.error(e);
        alert("There was an issue verifying your credentials. Please log in again.")
        navigate("/login");
      }
    }

    if (isAuthenticated) {
      checkRole();
    }
  }, [isAuthenticated, navigate, getAccessTokenSilently]);


  // Function to get list of template_categories and covert to dropdown options
  const getToolCategories = async () => {
    const token = await getAccessTokenSilently();
    const data: TemplateCategory[] = await getAllTemplateCategoriesApi(token);
    if (data) {
      setAllTemplateCategories(data); // this will store the full objects to the array

      // Convert unique category names into IDropDown format
      const dropdownOptions = data.map((template: TemplateCategory) => ({ key: template.category_name, text: template.category_name })).sort((a, b) => a.text.localeCompare(b.text));

      setDropdownOptions(dropdownOptions);

      return dropdownOptions;
    } else {
      console.error('Unexpected response data', data);
    }
  }

  // UseEffect to call getToolCategories on load
  useEffect(() => {
    getToolCategories();
  }, [])

  // Function to get/ refresh list of templates
  const listTemplates = async () => {
    setLoading(true);
    setTemplatesList([]); // clear templates list
    const token = await getAccessTokenSilently();
    const data: any = await getPromptTemplatesApi(token);
    if (data) {
      setTemplatesList(data);
    } else {
      console.error('Unexpected response data', data)
    }
    setLoading(false)
  }

  // When a template is selected from the list, make it active 
  const handleSelectTemplate = (template: PersonaTemplate) => {
    setActiveTemplate(template);
    setEditorData(template.long_description || ''); // load long_description into quill editor state
  }

  // Handle User Memory checkbox change
  // const handleUserMemoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setActiveTemplate({ ...activeTemplate!, user_memory: event.target.checked.toString() });
  // };

  // Handle the Search Type change
  // const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  //   setSelectedSearchType(event.target.value);
  // };

  // Handle Company Memory checkbox change
  const handleCompanyMemoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActiveTemplate({ ...activeTemplate!, company_memory: event.target.checked.toString() });
  };

  // Handle Hide In X words section checkbox change
  const handleHideVariableComponent = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnableVariableOne(event.target.checked);
    setActiveTemplate({ ...activeTemplate!, disable_variable_component: event.target.checked.toString() });
  }

  // Handle Hide In X words section checkbox change
  const handleHideVariableTwoComponent = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnableVariableTwo(event.target.checked);
    setActiveTemplate({ ...activeTemplate!, disable_variable_two_component: event.target.checked.toString() });
  }




  // Handle disable_document_collection checkbox change
  const handleDisableDocumentsCollectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisableDocumentCollections(event.target.checked)
    setActiveTemplate({ ...activeTemplate!, disable_document_collections: (!event.target.checked).toString() });
  };

  // Handle ask_anything_description checkbox change
  const handleDisableAskAnythingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisableSubmitQuery(event.target.checked)
    setActiveTemplate({ ...activeTemplate!, disable_ask_anything: (!event.target.checked).toString() });
  };

  //check tool options
  useEffect(() => {
    if (activeTemplate) {
      setEnableVariableOne(activeTemplate.disable_variable_component === "true");
      setEnableVariableTwo(activeTemplate.disable_variable_two_component === "true");
      setDisableDocumentCollections(activeTemplate.disable_document_collections === "true");
      setDisableSubmitQuery(activeTemplate.disable_ask_anything === "true");
    }
  }, [activeTemplate]);

  // Handle Search Only no AI checkbox change
  // const handleSearchOnlyModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setActiveTemplate({ ...activeTemplate!, search_only_mode: event.target.checked.toString() });
  // };

  // Handle disable_web checkbox change
  const handleDisableWebChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActiveTemplate({ ...activeTemplate!, disable_web: event.target.checked.toString() });
  };

  // Handle disable_history checkbox change
  const handleDisableHistoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActiveTemplate({ ...activeTemplate!, disable_history: event.target.checked.toString() });
  };

  // Handle disable_image_uploads checkbox change
  // const handleDisableImageUploadsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setActiveTemplate({ ...activeTemplate!, disable_image_uploads: event.target.checked.toString() });
  // };

  // Handle category dropdown change
  const handleDropdownChange: IDropdownProps['onChange'] = (_, option) => {
    if (option) {
      const selectedCategory = allTemplateCategories.find((category) => category.category_name === option.text);
      setSelectedCategory(selectedCategory);
      // Update the active template with the selected category
      setActiveTemplate({ ...activeTemplate!, template_category: option.text, color: selectedCategory?.color });
    }
  };

  // Handle the popover for adding a new category
  const toggleAddNewCategoryPopover = () => {
    setIsAddNewCategoryPopoverOpen(!isAddNewCategoryPopoverOpen);
  };

  // Handle the popover for editing a category
  const toggleEditCategoryPopover = () => {
    // fill the states before rendering the popover
    setEditCategoryId(selectedTemplateCategory?.id || '');
    setEditCategoryName(selectedTemplateCategory?.category_name || '');
    setEditCategoryDescription(selectedTemplateCategory?.description || '');
    setEditCategoryColor(selectedTemplateCategory?.color || '#000000');
    setIsEditCategoryPopoverOpen(!isEditCategoryPopoverOpen); // this opens it
  };

  // handle create a new category submit
  const handleNewCategoryFormSubmit = async () => {
    setLoading(true)
    const token = await getAccessTokenSilently();
    await createTemplateCategoryApi(newCategoryName, newCategoryDescription, newCategoryColor, token);
    setIsAddNewCategoryPopoverOpen(false);
    setNewCategoryName(''); // clear the name
    setNewCategoryDescription(''); // clear the description
    setLoading(false)
    getToolCategories();
  };

  // handle edit category form submit
  const handleEditCategoryFormSubmit = async () => {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      await updateTemplateCategoryApi(
        editCategoryId,
        editCategoryName,
        editCategoryDescription,
        editCategoryColor,
        token);
      setIsEditCategoryPopoverOpen(false);
      // TODO: trigger refresh of dropdown options
      getToolCategories();
    } catch (error) {
      console.error('Error editing category:', error);
    } finally {
      setLoading(false);
    }
  };

  // handle delete category
  const handleDeleteCategory = async () => {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      await deleteTemplateCategoryApi(editCategoryId, token);
      setIsEditCategoryPopoverOpen(false); // close the popover
      getToolCategories(); // refresh the dropdown options
    } catch (error) {
      console.error('Error deleting category:', error);
    } finally {
      setLoading(false);
    }
  };

  // Open the delete dialog
  const handleOpenDialog = () => {
    setDeleteDialogVisible(true);
    setMessage('');
  };

  // Close the delete dialog
  const handleCancelDelete = () => {
    setDeleteDialogVisible(false);
    setConfirmInput('');
  };

  // Handle create new template - just pass the name
  const createTemplate = async (name: string) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await createTemplateApi(name, token)
      if (response.status === 200) {
        setDialogVisible(false); // close the dialog
        setNewTemplateName(''); // clear the input
        listTemplates();
        notify(
          `${length} Persona Created`, // Title
          'Persona created successfully', // Body
          'success' // Message intent
        );
        //alert('Persona created successfully');

      } else {
        alert('Failed to create Persona');
      }
    } catch (error) {
      console.error('An error occurred:', error);
      //   alert('An unexpected error occurred while creating Persona')
      notify(
        `${length} An unexpected error occurred`, // Title
        'An error occurred: ' + error, // Body
        'error' // Message intent
      );
    }
  }

  // Handle delete - on click send the list of selected ids to delete
  const handleDelete = async (id: string) => {
    try {
      setIsDeleting(true);
      const token = await getAccessTokenSilently();
      await deleteTemplateApi(id, token);
      //  alert('Persona successfully deleted!')
      notify(
        `${length} Persona Deleted`, // Title
        'Persona successfully deleted', // Body
        'warning' // Message intent
      );
      listTemplates();
      setActiveTemplate(null);
      setDeleteDialogVisible(false);
    } catch (error) {
      notify(
        `${length} Error`, // Title
        'Failed to delete the template!', // Body
        'error' // Message intent
      );
    }
    finally {
      setIsDeleting(false);
    }
  }

  // Show and hide dialog
  const showDialog = () => {
    setDialogVisible(true);
  };

  //hide dialog
  const hideDialog = () => {
    setDialogVisible(false);
    setNewTemplateName(''); // clear the input
  };


  // Handle save - send data to backend
  const handleSave = async (event: React.FormEvent) => {
    setIsSaving(true);
    event.preventDefault();
    if (activeTemplate) {
      // Prepare the FormData object.
      const formData = new FormData();
      const iconUri = iconFilename ? `/icons/${iconFilename}` : activeTemplate.icon || '';
      formData.append('name', activeTemplate.name)
      formData.append('text', activeTemplate.text)
      formData.append('template_category', activeTemplate.template_category || "none")
      formData.append('color', activeTemplate.color || '#000000')
      formData.append('icon', iconUri);
      formData.append('iconName', activeTemplate.iconName || '')
      formData.append('description', activeTemplate.description || '')
      // When appending to form data, convert the number to a string
      formData.append('temperature', activeTemplate.temperature?.toString() || '')
      formData.append('endpoint', activeTemplate.endpoint || '')
      formData.append('question1', activeTemplate.question1 || '')
      formData.append('question2', activeTemplate.question2 || '')
      formData.append('question3', activeTemplate.question3 || '')
      formData.append('question4', activeTemplate.question4 || '')
      formData.append('long_description', editorData || '')
      // forms only use strings, convert the display_order number to a string
      formData.append('display_order', activeTemplate.display_order?.toString() || '');
      formData.append('company_memory', (activeTemplate.company_memory !== undefined ? activeTemplate.company_memory : false).toString()); // check if undefined and set to false
      formData.append('user_memory', (activeTemplate.user_memory !== undefined ? activeTemplate.user_memory : false).toString()); // if undefined set to false
      formData.append('semantic_search', (activeTemplate.semantic_search !== undefined ? activeTemplate.semantic_search : false).toString());
      formData.append('vector_search', (activeTemplate.vector_search !== undefined ? activeTemplate.vector_search : false).toString());
      formData.append('hybrid_search', (activeTemplate.hybrid_search !== undefined ? activeTemplate.hybrid_search : true).toString()); // default to true
      formData.append('top_k', activeTemplate.top_k?.toString() || '10'); // default to 10
      formData.append('search_only_mode', (activeTemplate.search_only_mode !== undefined ? activeTemplate.search_only_mode : false).toString());
      formData.append('web_top_k', activeTemplate.web_top_k?.toString() || '3'); // default to 3
      formData.append('disable_document_collections', (activeTemplate.disable_document_collections === 'true' ? true : false).toString());
      formData.append('disable_ask_anything', (activeTemplate.disable_ask_anything === 'true' ? true : false).toString());
      formData.append('disable_web', (activeTemplate.disable_web === 'true' ? true : false).toString());
      formData.append('disable_image_uploads', (activeTemplate.disable_image_uploads !== undefined ? activeTemplate.disable_image_uploads : false).toString());

      formData.append('disable_history', (activeTemplate.disable_history !== undefined ? activeTemplate.disable_history : false).toString());
      formData.append('input_character_limit', activeTemplate.input_character_limit?.toString() || '8000'); // default to 8k
      formData.append('response_character_limit', activeTemplate.response_character_limit?.toString() || '8000'); // default to 8k
      formData.append('disable_follow_up_questions', (activeTemplate.disable_follow_up_questions !== undefined ? activeTemplate.disable_follow_up_questions : false).toString());
      formData.append('follow_up_prompt', activeTemplate.follow_up_prompt || '');
      formData.append('fixed_query_text', activeTemplate.fixed_query_text || '');
      formData.append('prompt_helper_prompt', activeTemplate.prompt_helper_prompt || '');
      formData.append('ask_anything_description', activeTemplate.ask_anything_description || '');
      formData.append('document_collections_description', activeTemplate.document_collections_description || '');
      formData.append("disable_variable_component", (activeTemplate.disable_variable_component !== undefined ? activeTemplate.disable_variable_component : false).toString());
      formData.append("disable_variable_two_component", (activeTemplate.disable_variable_two_component !== undefined ? activeTemplate.disable_variable_two_component : false).toString());
      formData.append('variable_component_title', activeTemplate.variable_component_title || '');
      formData.append('variable_component_two_title', activeTemplate.variable_component_two_title || '');
      formData.append('variable_component_text', activeTemplate.variable_component_text || '');
      formData.append('variable_component_two_text', activeTemplate.variable_component_two_text || '');
      formData.append('use_gpt4o_mini', (activeTemplate.use_gpt4o_mini !== undefined ? activeTemplate.use_gpt4o_mini : false).toString());

      // pass to api function
      try {
        const token = await getAccessTokenSilently();
        await updateTemplateApi(activeTemplate.id, formData, token);
        // Display success toast
        notify(
          `${length} Save Successful`, // Title
          'Settings have been saved.', // Body
          'success' // Message intent
        );

        // Refresh list to see changes
        listTemplates();
      } catch (error) {
        notify(
          `${length} Warning`, // Title
          'Update failed, no persona selected.', // Body
          'error' // Message intent
        );
        console.error('Error updating persona: ', error)
      }
      finally {
        setIsSaving(false);
      }
    }
    else {
      console.log("No active Persona")
    }
  }
  useEffect(() => {
    if (activeTemplate) {
      if (activeTemplate.fixed_query_text != 'variable_1' && activeTemplate.fixed_query_text != 'variable_2') {
        setEnableFixedQueryText(true);
      }
    }
  }, [activeTemplate]);


  // useEffect to get list of templates on load
  useEffect(() => {
    listTemplates();
  }, [])


  // Category color swatches options
  const categoryColorSwatches = [
    { id: 'a', label: 'dark green', color: '#007b78' },
    { id: 'b', label: 'dark blue', color: '#1e4488' },
    { id: 'c', label: 'dark purple', color: '#5d3855' },
    { id: 'd', label: 'deep red', color: '#d3273e' },
    { id: 'e', label: 'black', color: '#000000' },
  ]

  const handleColorChange = (event: React.FormEvent<HTMLElement>, id: string | undefined, color: string | undefined) => {
    setEditCategoryColor(color || 'blue');
    setNewCategoryColor(color || 'blue');
    setActiveTemplate({ ...activeTemplate!, color: color });
  };

  // Check for error in input
  useEffect(() => {
    const promptTest = /[{}]/.test(activeTemplate?.text ?? '');
    const followupTest = /[{}]/.test(activeTemplate?.follow_up_prompt ?? '')
    const promptHelper = /[{}]/.test(activeTemplate?.prompt_helper_prompt ?? '')
    if (promptTest || promptHelper || followupTest) {
      setInputError(true);
    } else {
      setInputError(false);
    }
  }, [activeTemplate?.text]);



  // Permissions check
  if (!isAuthorizedToViewPersonaManager) {
    return <div><p>Not authorized to view this page, please contact your admin.</p></div>;
  }

  // Auth check
  if (isLoading) {
    return <div><p>Loading...</p></div>;
  }

  const groupedTemplates = templatesList.reduce((acc, template) => {
    const category = template.template_category;
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(template);
    return acc;
  }, {} as Record<string, PersonaTemplate[]>);


  const handleFixedQueryChange = (
    ev: FormEvent<HTMLDivElement>,
    data: RadioGroupOnChangeData
  ): void => {
    if (data.value === 'custom') {
      setEnableFixedQueryText(true)
      setActiveTemplate({ ...activeTemplate!, fixed_query_text: '' })

    } else {
      setEnableFixedQueryText(false)
      setActiveTemplate({ ...activeTemplate!, fixed_query_text: data.value })
    }
  };





  return (
    <div className='ms-Grid' dir='ltr'>
      {/* Toaster */}
      <Toaster
        toasterId={toasterId} // toasterId allows multiple to be sent
        position='top-start' // position of the toaster on the screen
      />

      <MaintenanceMode />
      {/* Create new category panel - these pop out from the side */}
      <Panel
        isOpen={isAddNewCategoryPopoverOpen}
        onDismiss={toggleAddNewCategoryPopover}
        type={PanelType.smallFixedFar} // adjust size and side of the screen it appears on Near = left, Far = right
        headerText="Add A new Category"
      >
        <TextField
          label="Category Name"
          value={newCategoryName}
          onChange={(e, newValue) => setNewCategoryName(newValue || '')}
        />
        <TextField
          label="Description"
          value={newCategoryDescription}
          onChange={(e, newValue) => setNewCategoryDescription(newValue || ' ')}
        />
        {/* Choose a color */}
        <label htmlFor="color">Choose color</label>
        {/* Color picker swatches */}
        <SwatchColorPicker
          columnCount={5}
          cellHeight={35}
          cellWidth={35}
          cellShape={'square'}
          colorCells={categoryColorSwatches}
          onChange={handleColorChange}
        />
        <input
          type="color"
          id="color"
          name="color"
          value={newCategoryColor}
          onChange={(e) => setNewCategoryColor(e.target.value)}
          style={{ display: '', width: '50px', height: '50px' }} // hide the default color input
        />
        <br />
        <br />
        {isLoading ? 'Loading...' : <PrimaryButton onClick={handleNewCategoryFormSubmit}>Create</PrimaryButton>}
      </Panel>

      {/* Edit a category panel */}
      <Panel
        isOpen={isEditCategoryPopoverOpen}
        onDismiss={toggleEditCategoryPopover}
        type={PanelType.smallFixedFar}
        headerText="Edit A Category"
      >
        <p>ID: {editCategoryId}</p>
        <br />
        <Text>Please note when renaming a collection, the tools will need to be reassigned to the updated category name.</Text>
        <br />
        <TextField
          label="Category Name"
          value={editCategoryName}
          onChange={(e, newValue) => setEditCategoryName(newValue || '')}
        />
        <TextField
          label="Description"
          value={editCategoryDescription}
          onChange={(e, newValue) => setEditCategoryDescription(newValue || '')}
        // resizable={true}
        />
        {/* color */}
        <label htmlFor="color">Choose color</label>

        {/* Color picker swatches */}
        <SwatchColorPicker
          columnCount={5}
          cellHeight={35}
          cellWidth={35}
          cellShape={'square'}
          colorCells={categoryColorSwatches}
          onChange={handleColorChange}
        />
        <label htmlFor='color'>Custom colour</label>
        <br></br>
        <input
          type="color"
          id="color"
          name="color"
          value={editCategoryColor}
          onChange={(e) => setEditCategoryColor(e.target.value)}
          style={{ display: '', width: '50px', height: '50px' }} // hide the default color input
        />
        <br />
        <br></br>
        <div className={styles.editButtonsContainer}>

          {/* Save Changes */}
          {loading ? <Text><Spinner /></Text> : <PrimaryButton onClick={handleEditCategoryFormSubmit}>Save Changes</PrimaryButton>}

          {/* Delete category */}
          <Button
            appearance='outline'
            style={{ color: 'red', border: '1px solid red' }}
            onClick={handleDeleteCategory}
          >
            Delete
          </Button>
        </div>
      </Panel>




      {/* Side menu */}
      <div className='ms-Grid-row'>
        {inputError ? <div className={styles.errorFlag}>Do Not Include Curly Brackets in any pompt text !! &#123; &#123;</div> : ''}

        <div className='ms-Grid-col ms-lg2'>

          <div className='ms-Grid' dir='ltr' style={{ height: '100vh' }}>

            <div className={styles.sideMenu}>
              <div className={styles.sideMenuTop}>
                <div className={styles.logoContainer}>
                  <div className={styles.logoContainer}>
                    {/* Client Logo */}
                    <Image className={styles.logo} src={ClientLogoTransparent} alt="Logo" />
                  </div>

                </div>

                {/* User info - wrap in isAuthenticated to prevent render leakage*/}
                <div className={styles.userDropdown}>
                  {isAuthenticated ? (
                    <UserProfile />
                  ) : null}
                </div>
                <ManagementToolbar />



                {/* Create a new Persona Button and Dialog */}
                {isAuthorizedToAdd && (
                  <div className={styles.newCollectionBtn}>
                    <Button
                      appearance="primary"
                      onClick={showDialog}
                      icon={<Add24Filled />
                      }
                    >
                      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                        <Text
                          as='span'
                          size={300}
                          weight='semibold'
                        >New Tool</Text>
                      </div>
                    </Button>
                    {/*Import / Export button */}
                    {activeTemplate ?
                      <div className={styles.importExport}>
                        <ImportTemplate
                          // pass in trigger to refresh the list when it closes
                          listTemplates={listTemplates}
                        />
                        <ExportTemplate id={activeTemplate.id} />
                      </div> : ''}
                    <Dialog
                      hidden={!dialogVisible}
                      onDismiss={hideDialog}
                      dialogContentProps={{
                        title: 'Create New Persona',
                      }}
                    >
                      <>
                        <Label>New Persona Name</Label>
                        <TextField
                          type="text"
                          placeholder='Enter new persona name'
                          value={newTemplateName}
                          onChange={(e, newValue) => setNewTemplateName(newValue || '')} // update state variable whenever text field changes - fluentui requires the newValue
                        />
                        {/* Submit */}
                        <PrimaryButton
                          type='submit'
                          onClick={() => createTemplate(newTemplateName)}
                          disabled={!newTemplateName.length} // disable if no name is text field
                        >
                          Create
                        </PrimaryButton>
                        {/* Cancel */}
                        <Button
                          appearance='outline'
                          onClick={hideDialog}
                        >Cancel</Button>
                      </>
                    </Dialog>
                  </div>
                )}
              </div>


              {/* Map out Personas */}
              {loading ? (
                <Spinner label="Loading templates..." ariaLive="assertive" labelPosition="right" />
              ) : templatesList.length > 0 ? (
                <div className={styles.personaList}>
                  {Object.keys(groupedTemplates)
                    .sort((a, b) => a.localeCompare(b))
                    .map((category) => (
                      <div key={category}>
                        <h3 className={styles.collectionCategory}>{category}</h3>
                        {groupedTemplates[category]
                          .sort((a, b) => {
                            if (a.display_order === undefined) return 1;
                            if (b.display_order === undefined) return -1;
                            return Number(a.display_order) - Number(b.display_order);
                          })
                          .map((template) => (
                            <div
                              className={styles.personaItem}
                              key={template.id}
                              onClick={() => handleSelectTemplate(template)}
                              style={template.name === activeTemplate?.name ? { backgroundColor: '#9ec7c9', color: template.color } : {}}
                            >
                              <PersonaIcon
                                iconName={template.iconName}
                                iconColor={template.color}
                                iconSize='small'
                              />
                              <Text
                                as='span'
                                size={300}
                              >
                                {template.name}
                              </Text>
                            </div>
                          ))}
                      </div>
                    ))}

                </div>
              ) : (
                <div>No templates found</div>
              )}

            </div>
          </div>
        </div>

        {/* Main content */}
        <div className='ms-Grid-col ms-lg5' style={{ height: '99vh' }}>
          <div className={styles.container}>

            {!activeTemplate ? (
              <div className={styles.selectACollection}>
                {/* Logo */}
                <ToolboxFilled style={{ fontSize: '10em' }} /><br></br>
                <Text
                  as='span'
                  size={600}
                >Tool Manager</Text>

                {/* Divider */}
                <div style={{ borderTop: '1px solid var(--primary-color)', width: '80%' }} />

                {/* Header Text */}
                <Text
                  as="h1"
                  size={300}
                  weight="bold"
                  align="center"
                  style={{ color: 'var(--bold-text)' }}
                >{"Create or Select a Persona to start."}
                </Text>

                {/* First Time graphic */}
                <ArrowCircleLeft32Filled />
              </div>) : (
              <>
                <div className={styles.optionsCollection}>
                  <div className={styles.optionsSubCollection}>
                    {/* Tool Name */}
                    <Label
                      htmlFor="Tool Name"
                      className={styles.promptNameLabel}
                    >Tool Name</Label>
                    <Input
                      className={styles.formInput}
                      type="text"
                      value={activeTemplate?.name || ''}
                      onChange={e => setActiveTemplate({ ...activeTemplate!, name: e.target.value })}
                      placeholder='Select a template to edit'
                    />

                    {/* Template ID */}
                    <p
                      className={styles.idLabel}
                    >ID: <span className={styles.id}>{activeTemplate ? activeTemplate.id : "Please select a template to edit"}</span></p>

                    {/* Template Category - with management buttons*/}
                    <div className={styles.categoryButtonsContainer}>
                      <Label className={styles.optionsCollectionTitle}>Tool Category</Label>
                      {/* Add new */}
                      <Button
                        onClick={toggleAddNewCategoryPopover}
                        style={{ width: 'auto', display: 'inline-block', margin: 'auto' }}
                      >
                        Add New Category
                      </Button>
                      <Button
                        onClick={toggleEditCategoryPopover}
                        style={{ width: 'auto', display: 'inline-block', margin: 'auto' }}
                        disabled={!selectedTemplateCategory}
                      >
                        Edit
                      </Button>
                    </div>
                    <Dropdown
                      disabled={activeTemplate ? false : true}
                      placeholder="Change category"
                      options={dropdownOptions}
                      onChange={handleDropdownChange}
                      selectedKey={activeTemplate?.template_category}
                    />
                  </div>

                  {/* Category Description */}
                  <div className={styles.optionsSubCollection}>
                    <Label className={styles.optionsCollectionTitle}>Tool Description</Label>

                    {/* enhanced description */}
                    <div className={styles.editorContainer}>
                      <label style={{ color: 'grey' }}>Note: Use HTML tags for formatting. </label>
                      <Textarea
                        resize='both'
                        value={editorData}
                        onChange={(e) => setEditorData(e.target.value)}
                        placeholder='Use <b>example</b> for bold, <u>example</u> for underline, and <br /> for a return / new line.'
                      />

                    </div>

                    {/* Icon Preview */}
                    <Label className={styles.optionsCollectionTitle}>Icon & Colour</Label>
                    <div className={styles.iconPicker}>
                      <a href='https://react.fluentui.dev/?path=/docs/icons-catalog--docs' target='_blank'>
                        <Button
                          icon={<Icons24Regular />}
                          title='Open Fluent UI Icon Catalog in a new tab'
                        >
                          Choose Icon from this list
                        </Button>
                      </a>

                      <div className={styles.personaIconSection}>

                        {/*Dynamic Icon*/}
                        <PersonaIcon iconName={activeTemplate?.iconName} iconSize='large' iconColor={activeTemplate?.color} />
                        <Input
                          type='text'
                          value={activeTemplate?.iconName || 'EditRegular'}
                          onChange={e => setActiveTemplate({ ...activeTemplate!, iconName: e.target.value })}
                        />

                        {/* Color select */}
                        {/* <input
                          type="color"
                          id="color"
                          name="color"
                          value={selectedTemplateCategory?.color || activeTemplate.color}
                          onChange={e => setActiveTemplate({ ...activeTemplate!, color: e.target.value })}
                          style={{ display: '', width: '50px', height: '50px' }} // hide the default color input
                        /> */}
                        {/* category colour */}
                        <div className={styles.categoryColorSwatch} style={{ color: editCategoryColor }}>*Colour defaults to category colour.</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={styles.optionsCollection}>
                  <form onSubmit={handleSave}>
                    <div className={styles.optionsSubCollectionFull}>

                      {/* Prompt Template text*/}
                      <Label className={styles.optionsCollectionTitle}>Tool Prompt Text</Label>
                      <Textarea
                        rows={10}
                        value={activeTemplate?.text || ''}
                        onChange={e => setActiveTemplate({ ...activeTemplate!, text: e.target.value })}
                        placeholder='Prompt Template'
                        className={styles.formInput}
                        resize='both'
                      />
                    </div>

                    <div className={styles.optionsSubCollection}>


                      {/* Temperature */}
                      <Label className={styles.promptNameLabel} htmlFor="Temperature range 0.0 to 1.0">Temperature</Label>
                      <span>{' '}{activeTemplate?.temperature || '0.0'}</span>
                      <input
                        type="range"
                        min="0"
                        max="1"
                        step=".1"
                        value={activeTemplate?.temperature || '0'}
                        onChange={(event) => {
                          if (activeTemplate) {
                            setActiveTemplate({
                              ...activeTemplate,
                              temperature: event.target.value
                            });
                          }
                        }}
                      />





                    </div>
                    {/* Buttons - save and delete */}
                    <div className={styles.buttonsContainer}>
                      {/* Save button */}
                      <Button
                        disabled={!activeTemplate || isSaving || inputError}
                        icon={isSaving ? <Spinner /> : <Save24Regular />}
                        appearance='primary'
                        onClick={handleSave}
                      >
                        {isSaving ? 'Saving...' : 'Save Changes'}
                      </Button>

                      {/* Delete */}
                      {!isAuthorizedToDelete ? null : (
                        <Button
                          disabled={!activeTemplate || isSaving}
                          onClick={handleOpenDialog}
                          className={styles.deleteButton}
                          icon={<Delete24Regular />}
                        >
                          Delete Persona
                        </Button>
                      )}
                    </div>


                    {/* Disable Image upload 
                  <Label>
                    Disable Image Uploads - images only work with a vision capable models gpt4o or gpt4vision the uploader will only appear when one is selected. this override will disable the image uploader even on valid models.
                    <input
                      type="checkbox"
                      checked={activeTemplate?.disable_image_uploads === 'true'}
                      onChange={handleDisableImageUploadsChange}
                    />
                  </Label> */}
                  </form>
                </div>



                {/* Delete Confirmation Dialog (pop up) */}
                <Dialog
                  hidden={!deleteDialogVisible}
                  onDismiss={handleCancelDelete}
                  dialogContentProps={{
                    title: 'Confirm Deletion',
                    subText: 'Are you sure you want to delete the Persona? To confirm you are happy to do this, please type "Delete" into the field below.'
                  }} >
                  <DialogContent>
                    <input
                      type="text"
                      onChange={(e) => setConfirmInput(e.target.value)}
                      onKeyPress={async (e) => {
                        if (e.key === 'Enter' && confirmInput === 'Delete') {
                          e.preventDefault(); // Prevent form submission
                          setIsDeleting(true);
                          await handleDelete(activeTemplate.id);
                          setIsDeleting(false);
                        }
                      }}
                    />
                  </DialogContent>
                  <DialogFooter>

                    {isDeleting ? <Spinner /> : <PrimaryButton
                      onClick={(event) => {
                        event.stopPropagation();
                        handleDelete(activeTemplate.id);
                      }}
                      text="Yes"
                      disabled={confirmInput !== 'Delete'}
                    />}
                    <DefaultButton onClick={handleCancelDelete} text="Close" />
                  </DialogFooter>
                  {/* Display the success/fail message - orange if fail */}
                  {message && (
                    <Text style={{ color: message.startsWith('Successfully') ? 'green' : 'orange' }}>
                      {message}
                    </Text>
                  )}
                </Dialog>
              </>
            )}
          </div>
        </div>






        {/* Right side */}
        <div className='ms-Grid-col ms-lg5' style={{ height: '99vh' }}>
          <div className={styles.container}>

            {!activeTemplate ? (
              <></>
            ) : (
              <>
                <div className={styles.rightSide}>
                  <Label className={styles.optionsCollectionTitle}>Tool Options</Label>


                  <form >

                    {/* Disable Document Collections */}
                    <Switch
                      label={"Enable Document Collections Section"}
                      checked={!disableDocumentCollections}
                      onChange={handleDisableDocumentsCollectionChange}
                    />
                    {!disableDocumentCollections ?
                      <div className={styles.optionsIndent}>
                        <Field label={"Collection Instruction Text"}>
                          <Textarea
                            rows={2}
                            value={activeTemplate?.document_collections_description || ''}
                            onChange={e => setActiveTemplate({ ...activeTemplate!, document_collections_description: e.target.value })}
                            placeholder='Use <b>example</b> for bold, <u>example</u> for underline, and <br /> for a return / new line.'
                            style={{ width: '100%' }}
                            resize='both'
                          />
                        </Field>
                      </div>
                      : ''}

                    <Switch
                      label={"Enable Submit Query Section"}
                      checked={!disableSubmitQuery}
                      onChange={handleDisableAskAnythingChange}
                    />
                    {!disableSubmitQuery ?
                      <div className={styles.optionsIndent}>
                        <Field label={"Query Instruction Text"}>

                          <Textarea
                            rows={2}
                            value={activeTemplate?.ask_anything_description || ''}
                            onChange={e => setActiveTemplate({ ...activeTemplate!, ask_anything_description: e.target.value })}
                            placeholder='Use <b>example</b> for bold, <u>example</u> for underline, and <br /> for a return / new line.'
                            style={{ width: '100%' }}
                            resize='both'
                          />
                          {/* Input character limit - slider from 100 to 100,000 increment 100*/}
                          <Label htmlFor="Character limit range 100 to 100,000">Input Character Limit:
                            <span>{' '}{activeTemplate?.input_character_limit || '8000'}</span>
                            <input
                              type="range"
                              min="100"
                              max="100000"
                              step="100"
                              value={activeTemplate?.input_character_limit || '4000'} // default to 4000
                              onChange={(event) => {
                                setActiveTemplate({
                                  ...activeTemplate,
                                  input_character_limit: Number(event.target.value)
                                });
                              }}
                              style={{ width: '90%' }}
                            />
                          </Label>
                        </Field>

                        <Switch
                          label={"Enable Follow up Questions"}
                          checked={activeTemplate?.disable_follow_up_questions === 'false'}
                          onChange={(event) => setActiveTemplate({ ...activeTemplate!, disable_follow_up_questions: (!event.target.checked).toString() })}
                        />
                        {/* Follow up prompt */}
                        {activeTemplate?.disable_follow_up_questions === 'false' ?
                          <div className={styles.optionsIndent}>
                            <Field label={"Follow up question override prompt text"}>
                              <Textarea
                                rows={2}
                                value={activeTemplate?.follow_up_prompt || ''}
                                onChange={e => setActiveTemplate({ ...activeTemplate!, follow_up_prompt: e.target.value })}
                                placeholder='Follow up override prompt'
                                className={styles.formInput}
                                resize='both'
                              />
                            </Field>
                          </div>
                          : ''}
                      </div>
                      : ''}
                    {disableSubmitQuery ?
                      <div className={styles.optionsIndent}>
                        <p><strong>When no user submitted query is used, you must define a fixed query.</strong></p>
                        <Field label={"Fixed query options:"} required>
                          <RadioGroup defaultValue={activeTemplate?.fixed_query_text === 'variable_1' ? 'variable_1' : activeTemplate?.fixed_query_text === 'variable_2' ? 'variable_2' : 'custom'} onChange={handleFixedQueryChange}>
                            <Radio value="custom" label="Use Custom Input" />
                            <Radio value="variable_1" disabled={!enableVariableOne} label="Use Variable 1 User Input" />
                            <Radio value="variable_2" disabled={!enableVariableTwo} label="Use Variable 2 User Input" />

                          </RadioGroup>
                          {enableCustomFixedQueryText ?
                            <Textarea
                              rows={2}
                              value={activeTemplate?.fixed_query_text || ''}
                              onChange={e => setActiveTemplate({ ...activeTemplate!, fixed_query_text: e.target.value })}
                              placeholder='Use <b>example</b> for bold, <u>example</u> for underline, and <br /> for a return / new line.'
                              style={{ width: '100%' }}
                              resize='both'
                            />
                            : ''}

                        </Field>

                      </div>
                      : ''}
                    <Switch
                      label={"Use GPT4o Mini"}
                      checked={activeTemplate?.use_gpt4o_mini === 'true'}
                      onChange={(event) => setActiveTemplate({ ...activeTemplate!, use_gpt4o_mini: event.target.checked.toString() })}
                    />
                    <p>  <a
                      className={styles.infoLink}
                      href='https://openai.com/index/gpt-4o-mini-advancing-cost-efficient-intelligence/' target='_blank'>Gpt4o-mini info</a></p>


                    {/* Memory checkboxes */}
                    {/* <Label>
                        Include User Memory:
                        <input
                          type="checkbox"
                          checked={activeTemplate?.user_memory === 'true'}
                          onChange={handleUserMemoryChange}
                        />
                      </Label> */}

                    <Switch
                      label={"Include Company Profile"}
                      checked={activeTemplate?.company_memory === 'true'}
                      onChange={handleCompanyMemoryChange}
                    />

                    {/* Variable component 1 */}

                    <Switch
                      label={"Enable variable component 1"}
                      checked={activeTemplate?.disable_variable_component === 'true'}
                      onChange={handleHideVariableComponent} />

                    {/* Variable text input */}
                    {enableVariableOne ?
                      <div className={styles.optionsIndent}>
                        <Field label={"Variable One Title"}>
                          <Textarea
                            rows={1}
                            value={activeTemplate?.variable_component_title || ''}
                            onChange={e => setActiveTemplate({ ...activeTemplate!, variable_component_title: e.target.value })}
                            placeholder='Variable component title'
                          />
                        </Field>
                        <Field label={"Variable One Instruction Text"} hint={"use variable_1 in the tool prompt text"}>
                          <Textarea
                            rows={2}
                            value={activeTemplate?.variable_component_text || ''}
                            onChange={e => setActiveTemplate({ ...activeTemplate!, variable_component_text: e.target.value })}
                            placeholder='Variable component instructions'
                            resize='both'
                          />
                        </Field>
                        <Divider />
                      </div>
                      : ''}


                    {/* Variable component two */}

                    <Switch
                      label={"Enable variable component 2"}
                      checked={activeTemplate?.disable_variable_two_component === 'true'}
                      onChange={handleHideVariableTwoComponent}
                    />
                    {/* Variable component two text input */}
                    {enableVariableTwo ?
                      <div className={styles.optionsIndent}>
                        <Field label={"Variable Two Title"}>
                          <Textarea
                            rows={1}
                            value={activeTemplate?.variable_component_two_title || ''}
                            onChange={e => setActiveTemplate({ ...activeTemplate!, variable_component_two_title: e.target.value })}
                            placeholder='Variable component title'
                          />
                        </Field>
                        <Field label={"Variable Two Instruction Text"} hint={"use variable_2 in the tool prompt text"}>
                          <Textarea
                            rows={2}
                            value={activeTemplate?.variable_component_two_text || ''}
                            onChange={e => setActiveTemplate({ ...activeTemplate!, variable_component_two_text: e.target.value })}
                            placeholder='Variable component instructions'
                            resize='both'
                          />
                        </Field>
                        <Divider />
                      </div>
                      : ''}




                    {/* Disable Web */}
                    <Switch
                      label={"Disable Web"}
                      checked={activeTemplate?.disable_web === 'true'}
                      onChange={handleDisableWebChange}
                    />
                    {/* Disable History */}
                    <Switch
                      label={"Disable History"}
                      checked={activeTemplate?.disable_history === 'true'}
                      onChange={handleDisableHistoryChange}
                    />

                    {/* Top_k - Number of documents to return during search */}
                    <div style={{ border: '1px solid lightgrey', padding: '5px', marginBottom: '5px' }}>
                      <p>Total Number of sections to return during search. Will be divided by the number of collections selected.</p>
                      <Label className={styles.promptNameLabel} htmlFor="Document range 0 to 100">Top_k
                        <span>{' '}{activeTemplate?.top_k || '0'}</span>

                        <input
                          type="range"
                          min="1"
                          max="100"
                          step="1"
                          value={activeTemplate?.top_k !== undefined && activeTemplate?.top_k !== null ? activeTemplate?.top_k : '10'}
                          onChange={(event) => {
                            setActiveTemplate({
                              ...activeTemplate,
                              top_k: Number(event.target.value)
                            });
                          }}
                          style={{ width: '90%' }}

                        />
                      </Label>
                    </div>

                    {/* Web Top K */}
                    <div style={{ border: '1px solid lightgrey', padding: '5px' }}>
                      <p>Number of Documents to return from Tavily Web Search</p>
                      <Label className={styles.promptNameLabel} htmlFor="Document range 0 to 100">Web Top_k
                        <span>{' '}{activeTemplate?.web_top_k || '0'}</span>

                        <input
                          type="range"
                          min="1"
                          max="100"
                          step="1"
                          value={activeTemplate?.web_top_k !== undefined && activeTemplate?.web_top_k !== null ? activeTemplate?.web_top_k : '3'}
                          onChange={(event) => {
                            setActiveTemplate({
                              ...activeTemplate,
                              web_top_k: Number(event.target.value)
                            });
                          }}
                          style={{ width: '90%' }}

                        />
                      </Label>
                    </div>

                    {/* Prompt helper prompt */}
                    <div className={styles.optionsCollection}>
                      <Label>Prompt Helper prompt</Label>
                      <Textarea
                        rows={2}
                        value={activeTemplate?.prompt_helper_prompt || ''}
                        onChange={e => setActiveTemplate({ ...activeTemplate!, prompt_helper_prompt: e.target.value })}
                        placeholder='Prompt helper prompt.'
                        style={{ width: '100%' }}
                        resize='both'
                      />
                    </div>




                  </form>
                </div>
              </>
            )}
          </div>
        </div>
      </div >
    </div >
  );
};


export default PersonaManager;
import { Stack } from "@fluentui/react";
import React, { useState, useEffect } from 'react';

import { animated, useSpring } from "@react-spring/web";
import { Skeleton, SkeletonItem, Spinner } from "@fluentui/react-components";

import styles from "./Answer.module.css";
import { AnswerIcon } from "./AnswerIcon";


export const AnswerLoading = () => {
    const texts = ["1. Analysing Documents", "2. Selected Relevant Source Text", "3. Preparing Results"]; // Array of texts
    const [currentIndex, setCurrentIndex] = useState(0); // State to track the current index

    useEffect(() => {
        if (currentIndex < texts.length - 1) {
            const intervalId = setInterval(() => {
                setCurrentIndex(prevIndex => prevIndex + 1);
            }, 2000); // Change highlighted text every 2 seconds

            return () => clearInterval(intervalId); // Cleanup interval on component unmount
        }
    }, [currentIndex, texts.length]); // Dependency array includes 'currentIndex' and 'texts.length'




    const animatedStyles = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 }
    });

    return (
        <animated.div style={{ ...animatedStyles }}>
            <Stack className={styles.answerContainer} verticalAlign="space-between">
                <Stack.Item grow>
                    <div className={styles.answerText}>
                        {texts.map((text, index) => (
                            <p
                                key={index}
                                className={currentIndex === index ? styles.highlighted : styles.loadingText}
                            >
                                {currentIndex === index ? <Spinner className={styles.loadingSpinner} size="extra-small" label={text} /> : text}

                            </p>
                        ))}
                    </div>
                </Stack.Item>
            </Stack>
        </animated.div>
    );
};

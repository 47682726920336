import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@fluentui/react-components";


interface LoginButtonProps {
  className?: string;

}

const LoginButton = ({ className }: LoginButtonProps) => {
  const { loginWithRedirect } = useAuth0();

  return <Button
    onClick={() => loginWithRedirect()}
    size="large"
  >
    LOGIN
  </Button>;
};

export default LoginButton;
// Collection Manager

import { useAuth0 } from '@auth0/auth0-react';
import { Dialog } from '@fluentui/react';
import type { MenuButtonProps } from "@fluentui/react-components";
import { Button, Checkbox, Image, makeStyles, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, shorthands, Skeleton, SkeletonItem, Spinner, SplitButton, Text } from '@fluentui/react-components';
import { Add24Filled, Archive16Regular, ArrowCircleLeft32Filled, ArrowSync20Regular, Chat24Regular, ChevronRight16Filled, Home24Filled, Settings20Regular } from '@fluentui/react-icons';
import { jwtDecode } from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { lightTheme } from '../../BlueEdgeTheme';
import { CollectionName, CustomJwtPayload, getCollectionsApi, retrieveFileApi, SelectedFile } from '../../api';
import BEAILogo from "../../assets/BEAILogoFinal.png";
import ClientLogoTransparent from "../../assets/LogoTransparent.png";
import CreateNewIndex from '../../components/CreateNewIndex/CreateNewIndex';
import { DeleteCollection } from '../../components/DeleteCollection/DeleteCollection';
import FileStatus from '../../components/FileStatus/FileStatus';
import { MaintenanceMode } from "../../components/MaintenanceMode";
import { ManagementToolbar } from '../../components/ManagmentToolbar';

import UserProfile from '../../components/UserProfile/UserProfile';
import styles from './CollectionManager.module.css';

import UploadStatus from '../../components/UploadStatus/UploadStatus';



// Styles for the skeleton
const useStyles = makeStyles({
    skeletonRow: {
        alignItems: "center",
        display: "grid",
        paddingBottom: "10px",
        position: "relative",
        ...shorthands.gap("20px"),
        gridTemplateColumns: "80% 20%",
        height: '5vh', // matches the height of the personaListItem
    },
    text: {
        color: lightTheme.colorBrandForeground1
    },
});

const CollectionManager: React.FC = () => {
    const navigate = useNavigate();
    const { isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();
    const [isAuthorizedToCreateIndex, setIsAuthorizedToCreateDb] = React.useState(false);
    const [isAuthorizedToDeleteIndex, setIsAuthorizedToDeleteDb] = React.useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isAuthorizedToDeleteAll, setIsAuthorizedToDeleteAll] = React.useState(false);
    const [isAuthorizedToUpload, setIsAuthorizedToUpload] = React.useState(false);
    const [isAuthorizedToViewDocumentCollectionsManager, setIsAuthorizedToViewDocumentCollectionsManager] = useState<boolean>(false); // check if user is authorized to view THIS page
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isAuthorizedToViewQuickSettingsMenu, setIsAuthorizedToViewQuickSettingsMenu] = useState<boolean>(false);

    const [collections, setCollections] = useState<CollectionName[] | undefined>(undefined); // hold list of all collections
    const [selectedCollection, setSelectedCollection] = useState<CollectionName | undefined>(undefined); // track which collection is selected
    const [pdfUrl, setPdfUrl] = useState<string | null>(null); // hold the pdf data for the previewer
    const [dialogVisible, setDialogVisible] = useState(false); // show/hide the dialog
    const [selectedPdf, setSelectedPdf] = useState<SelectedFile | null>(null); // track which pdf is selected 
    const [refreshGrid, setRefreshGrid] = useState(false); // refresh the DataGrid after upload/delete
    const [isPdfLoading, setIsPdfLoading] = useState(false); // show loading while pdf is being retrieved
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isPdfDownloading, setIsPdfDownloading] = useState(false); // show loading while pdf is being downloaded
    const [numberOfFilesInSelectedCollection, setNumberOfFilesInSelectedCollection] = useState<number>(0); // hold the number of files in the selected collection
    const [autoRefresh, setAutoRefresh] = useState(true); // track if auto refresh is enabled
    const [error, setError] = useState<string>("");

    // Fluent UI styles
    const fuistyles = useStyles();

    // Functions to show/hide the dialog
    const showDialog = () => {
        setDialogVisible(true);
    };

    const hideDialog = () => {
        setDialogVisible(false);
        fetchCollectionNames(); // refresh the collections list
    };


    // Check auth
    useEffect(() => {
        if (!isAuthenticated) {
            console.log("Redirect triggered at [collectionManager.tsx]")
            navigate("/login");
        }
        else {
            // add the history
            window.history.replaceState(null, '', '/#/collections')
        }
    }, [isAuthenticated, navigate]);

    // get fresh access token and check roles
    useEffect(() => {
        const checkRole = async () => {
            try {
                const token = await getAccessTokenSilently();
                const decodedToken = jwtDecode<CustomJwtPayload>(token);
                const permissions = decodedToken.permissions;
                if (permissions.includes('view:CollectionsManager')) {
                    setIsAuthorizedToViewDocumentCollectionsManager(true);
                }
                if (permissions.includes('add:Databases')) {
                    setIsAuthorizedToCreateDb(true);
                }
                if (permissions.includes('delete:Databases')) {
                    setIsAuthorizedToDeleteDb(true);
                }
                if (permissions.includes('delete:Files')) {
                    setIsAuthorizedToDeleteAll(true);
                }
                if (permissions.includes('add:Files')) {
                    setIsAuthorizedToUpload(true);
                }
                if (permissions.includes('view:QuickSettingsMenu')) {
                    setIsAuthorizedToViewQuickSettingsMenu(true);
                }
            } catch (e) {
                console.error(e);
                alert("There was an error accessing your user permissions, please try to log in again.")
                navigate("/login");
            }
        }
        if (isAuthenticated) {
            checkRole();
        }
    }, [isAuthenticated, navigate, getAccessTokenSilently]);

    // Define function to fetch collection names
    const fetchCollectionNames = async () => {
        try {
            const token = await getAccessTokenSilently();
            const collectionNames = await getCollectionsApi(token) as CollectionName[];
            setCollections(collectionNames);
        } catch (error) {
            console.error('An error occurred while fetching the indexes:', error);
        };
    };

    // Fetch collection names on load
    useEffect(() => {
        fetchCollectionNames();
    }, []);

    // Preview Select - Use effect to get the pdf from the db to display in the preview
    useEffect(() => {
        const fetchPdf = async () => {
            // Checks to make sure all data is present before fetching the pdf

            // If selectedPdf is null, undefined, or id is an empty string, clear the pdfUrl state and return
            if (!selectedPdf || selectedPdf.id === '') {
                setPdfUrl(null); // clear pdf preview
                return;
            }
            // If index_name is empty clear the pdfUrl state and return
            if (!selectedPdf || selectedPdf.indexName === '') {
                setPdfUrl(null); // clear pdf preview
                return;
            }
            // If document_id is empty clear the pdfUrl state and return
            if (!selectedPdf || selectedPdf.document_id === '') {
                setPdfUrl(null); // clear pdf preview
                return;
            }
            // Check if the file is not a PDF by checking the file extension
            if (!selectedPdf.id.toLowerCase().endsWith('.pdf')) {
                setPdfUrl(null); // clear pdf preview
                setError("This file is not a PDF and cannot be previewed.");
                setIsPdfLoading(false); // Assuming you want to stop the loading indicator
                return; // Exit the function early
            }
            // End checks

            // set loading true
            setIsPdfLoading(true);
            // clear any existing pdf file from state
            setPdfUrl(null);
            // clear any errors
            setError("");
            try {
                const token = await getAccessTokenSilently();
                const fileUrl = await retrieveFileApi(
                    // Order: index_name:string, document_id:string, filename:string, token:string
                    selectedPdf.indexName,
                    selectedPdf.document_id,
                    selectedPdf.id,
                    token // auth0 token
                );
                if (fileUrl) {
                    setPdfUrl(fileUrl);
                } else {
                    // If fileUrl is undefined, clear the pdfUrl state
                    setPdfUrl(null);
                }
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
                console.error(error);
                setError(error.message);
            } finally {
                setIsPdfLoading(false);
            }
        };
        fetchPdf();
    }, [selectedPdf]);


    // Loading
    if (isLoading) {
        return <div className='loading'><Spinner size='large' label='Loading' labelPosition="below" /></div>;
    }

    // Permissions check
    if (!isAuthorizedToViewDocumentCollectionsManager) {
        return <div>You do not have Permission to view this page. Please contact your admin.</div>;
    }

    return (
        <div className='ms-Grid' dir='ltr'>
            <MaintenanceMode />
            <div className='ms-Grid-row'>
                {/* Side menu */}
                <div className='ms-Grid-col ms-lg2'>
                    <div className='ms-Grid' dir='ltr' style={{ height: '99vh' }}>
                        <div className={styles.sideMenu}>
                            <div className={styles.sideMenuTop}>
                                <div className={styles.logoContainer}>
                                    {/* Client Logo */}
                                    <Image className={styles.logo} src={ClientLogoTransparent} alt="Logo" />
                                </div>

                                {/* User info - wrap in isAuthenticated to prevent render leakage*/}
                                <div className={styles.userDropdown}>
                                    {isAuthenticated ? (
                                        <UserProfile />
                                    ) : null}
                                </div>

                                {/* Management Toolbar */}
                                <ManagementToolbar />

                                {/* Back to chat */}
                                <div className={styles.backToChatBtn}>

                                    <Button
                                        appearance="secondary"
                                        onClick={() => window.location.href = "/#/hello"}
                                        icon={<Home24Filled />}
                                    >
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                            <Text
                                                as='span'
                                                size={300}
                                                weight='semibold'
                                            >Back to Hello</Text>
                                        </div>
                                    </Button>
                                </div>

                                {/* Create New Collection */}
                                <div className={styles.newCollectionBtn}>
                                    <Button
                                        appearance="primary"
                                        onClick={showDialog}
                                        icon={<Add24Filled />
                                        }
                                        disabled={collections?.length == 15 ? true : false}
                                    >
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                            <Text
                                                as='span'
                                                size={300}
                                                weight='semibold'
                                            >New Collection</Text>
                                        </div>
                                    </Button>

                                    {/* Create new collection Dialog */}
                                    <Dialog
                                        hidden={!dialogVisible}
                                        onDismiss={hideDialog}
                                        dialogContentProps={{
                                            title: 'Create New Collection',
                                        }}
                                    >
                                        {!isAuthorizedToCreateIndex ? '' : (
                                            <CreateNewIndex hideDialog={hideDialog} />
                                        )}
                                    </Dialog>

                                    {/* Auth message */}
                                    {!isAuthorizedToCreateIndex ? <div>You are not authorized to create a new database.</div> : null}
                                </div>
                                <div>
                                    <h4>Document Collections {collections?.length} / 15</h4>
                                </div>






                                {/* Collection List */}
                                <div className={styles.collectionsContainer}>
                                    {collections && collections.length > 0 ? (
                                        collections.map((collection, index) => (
                                            <div
                                                key={index}
                                                className={styles.collectionItem}
                                                onClick={() => setSelectedCollection(collection)}
                                                style={collection === selectedCollection ? { backgroundColor: '#00b0ba', color: 'white' } : {}}
                                            >
                                                <Archive16Regular />
                                                <Text
                                                    as='span'
                                                    size={300}
                                                >
                                                    {/* Show the display name instead of the real name */}
                                                    {collection.display_name}
                                                </Text>
                                                <ChevronRight16Filled className={styles.chevron} />
                                            </div>
                                        ))
                                    ) : (
                                        // display skeleton while loading
                                        <div style={{ textAlign: 'center', padding: '20px' }}>
                                            <Text
                                                as='p'
                                                size={200}
                                                className={fuistyles.text}
                                            >Loading Collections...</Text>
                                            <Skeleton >
                                                <div className={fuistyles.skeletonRow}>
                                                    <SkeletonItem />
                                                    <SkeletonItem shape="circle" size={24} />
                                                </div>
                                            </Skeleton>
                                            <Skeleton >
                                                <div className={fuistyles.skeletonRow}>
                                                    <SkeletonItem />
                                                    <SkeletonItem shape="circle" size={24} />
                                                </div>
                                            </Skeleton>
                                            <Skeleton >
                                                <div className={fuistyles.skeletonRow}>
                                                    <SkeletonItem />
                                                    <SkeletonItem shape="circle" size={24} />
                                                </div>
                                            </Skeleton>
                                        </div>
                                    )}
                                </div>

                            </div>
                            <div className={styles.sideMenuSettings}>
                                <h4><Settings20Regular /> Settings</h4>
                                {/* Support access settings - commented out for now as working on different deployment*/}
                                {/* <AllowSupportAccess isAdmin={isAuthorizedToViewQuickSettingsMenu} userName={user?.email} /> */}
                            </div>

                        </div>
                    </div>
                </div>



                {/* Main content */}
                <div className='ms-Grid-col ms-lg5' style={{ height: '99vh' }}>
                    {selectedCollection ? (
                        <>
                            <div className={styles.main}>
                                {/* Name of Collection Selected */}
                                <Text
                                    as='span'
                                    size={600}
                                    weight='semibold'
                                    style={{ marginBottom: '20px' }}
                                >{selectedCollection ? selectedCollection.display_name : "Select a collection"}</Text>

                                {/* maybe we can move the following into a little "more info" dialog component or tool tip */}
                                <Text>id: {selectedCollection.id}</Text>
                                <Text>Real name: {selectedCollection.collection_name}</Text>
                                <Text>Created on: {selectedCollection.entry_date}</Text>
                                <Text>Created by: {selectedCollection.user_id}</Text>

                                {/* Divider */}
                                <div style={{ borderTop: '1px solid var(--primary-color)', width: '80%' }} />

                                {/* size of collection & refresh button */}
                                <div className={styles.collectionHeader}>
                                    {/* Display count of files*/}
                                    <Text
                                        size={400}
                                        weight='semibold'
                                        style={{ color: 'var(--primary-color)' }}
                                    >{numberOfFilesInSelectedCollection ? numberOfFilesInSelectedCollection : "0"} files
                                    </Text>

                                    {/*refresh button*/}
                                    <Menu positioning="below-end">
                                        <MenuTrigger disableButtonEnhancement>
                                            {(triggerProps: MenuButtonProps) => (
                                                <SplitButton
                                                    menuButton={triggerProps}
                                                    primaryActionButton={{ onClick: () => setRefreshGrid(true) }}
                                                    icon={<ArrowSync20Regular />}
                                                >
                                                    Reload List
                                                </SplitButton>
                                            )}
                                        </MenuTrigger>
                                        <MenuPopover>
                                            <MenuList>
                                                <MenuItem persistOnClick={true}>
                                                    <Checkbox
                                                        label="Auto-refresh"
                                                        defaultChecked={autoRefresh}
                                                        onChange={() => setAutoRefresh(!autoRefresh)}
                                                    />
                                                </MenuItem>
                                            </MenuList>
                                        </MenuPopover>
                                    </Menu>
                                </div>

                                {/* File upload button and dropzone */}
                                {isAuthorizedToUpload ? (
                                    <div className={styles.uploadContainer}>
                                        {/* <UploadArea
                                            indexName={selectedCollection.collection_name}
                                            setRefreshGrid={setRefreshGrid}
                                            setHasUpload={}
                                        /> */}
                                        <Text
                                            as='span'
                                        >When uploading files to the AI, please be aware that larger files and complex PDFs will take longer to process. Please remove any large CAD or Vector files from the PDF before uploading.</Text>
                                    </div>
                                ) : ('')}

                                {/* New - Upload URL to Collection - not working yet */}
                                {/* <div>
                                    <UploadUrl index_name={selectedCollection.collection_name} />
                                </div> */}

                                {/* List Files */}
                                <div className={styles.fileStatusContainer}>

                                </div>

                                {/* Delete button */}
                                <div className={styles.deleteContainer}>
                                    {isAuthorizedToDeleteIndex ? (
                                        <DeleteCollection
                                            indexName={selectedCollection ? selectedCollection.collection_name : ''}
                                            fetchCollections={fetchCollectionNames}
                                            setSelectedCollection={setSelectedCollection}
                                            setSelectedPdf={setSelectedPdf}
                                        />
                                    ) : ('')} {/* Blank if not authorized to delete */}
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className={styles.selectACollection}>
                            {/* Logo */}
                            <Image
                                src={BEAILogo}
                                alt="BEAI Logo"
                                height={102}
                            />
                            <Text
                                as='span'
                                size={600}
                            >Document Collection Manager</Text>

                            {/* Divider */}
                            <div style={{ borderTop: '1px solid var(--primary-color)', width: '80%' }} />
                            {/* Header Text */}

                            <Text
                                as="h1"
                                size={300}
                                weight="bold"
                                align="center"
                                style={{ color: 'var(--bold-text)' }}
                            >{"Create or Select a Collection to start."}
                            </Text>

                            {/* First Time graphic */}
                            <ArrowCircleLeft32Filled />
                        </div>
                    )}
                </div>


                {/* PDF Preview side */}
                <div className='ms-Grid-col ms-lg5' style={{ height: '99vh' }}>
                    <div className={styles.rightSide}>
                        <div className={styles.emptyCitation}>
                            <div className={styles.emptyCitationText}>
                                <Text
                                    size={400}
                                    weight="bold"
                                    style={{ color: 'var(--disabled-text)' }}
                                >
                                    {selectedPdf ? selectedPdf.id : "Select a pdf document"}
                                </Text>

                            </div>
                        </div>

                        <div className={styles.emptyCitationBox}>
                            {/* Display loading spinner, pdf, error message, or prompt to select a PDF */}
                            {isPdfLoading ? (
                                <div className={styles.loadingContainer}>
                                    <Spinner />
                                    <br />
                                    <Text style={{ color: 'var(--primary-color)' }}>Loading your file, please wait...</Text>
                                    <Text style={{ color: 'var(--primary-color)' }}>Large files can take a while</Text>
                                </div>
                            ) : pdfUrl ? (
                                <div className={styles.pdfContainer}>
                                    <iframe
                                        title="File Preview"
                                        src={pdfUrl}  // src attribute of iframe needs to be an url not raw data
                                        style={{ width: '100%', height: '100%' }}
                                    />
                                </div>
                            ) : error ? (
                                // Display error message if there is one
                                <p>{error}</p>
                            ) : (
                                // Prompt to select a PDF if no URL and no error
                                <p>Select a PDF to view.</p>
                            )}
                        </div>



                        {/* Download link   */}
                        <div>
                            {pdfUrl && selectedPdf ? (
                                <a
                                    href={pdfUrl} // use the blob URL from state
                                    download={`${selectedPdf.id}`} // use the file name from state, ensuring selectedPdf is not null
                                    style={{ color: 'blue' }}
                                    onClick={(e) => {
                                        if (isPdfDownloading || !pdfUrl) {
                                            // prevent download if the file is still loading or not available
                                            e.preventDefault();
                                        }
                                    }}
                                >
                                    {isPdfDownloading ? 'Downloading...' : 'Download Original PDF'}
                                </a>
                            ) : (
                                <Text
                                    size={300}
                                    style={{ color: 'var(--disabled-text)' }}
                                >
                                    Download Original PDF
                                </Text>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CollectionManager
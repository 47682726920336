import { Stack } from "@fluentui/react";
import { ChatDismissRegular, ChevronRightRegular } from "@fluentui/react-icons";
import { useEffect, useRef, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { Button, Field, Link, ProgressBar, Textarea, TextareaOnChangeData, Toast, ToastBody, Toaster, ToastTitle, useId, useToastController } from "@fluentui/react-components";
import { improveQuestionApi, PersonaTemplate } from "../../api";
import styles from "./QuestionInput.module.css";



interface Props {
    onSend: (question: string) => void;
    disabled: boolean;
    placeholder?: string;
    clearOnSend?: boolean;
    selectedPersona?: PersonaTemplate;
    inputLength?: number;
    setInputLength: (length: number) => void;
    maxInputLength: number;
    originalPrompt?: string;
    chatBoxValue: string;
    followupQuestions?: string[];
    setChatboxInput?: React.Dispatch<React.SetStateAction<string>>;
    clearChatBox?: boolean;
}



export const QuestionInput = ({ onSend, disabled, placeholder, clearOnSend, setInputLength, selectedPersona, maxInputLength, originalPrompt, chatBoxValue, followupQuestions, setChatboxInput, clearChatBox }: Props) => {
    const { getAccessTokenSilently } = useAuth0();
    const [question, setQuestion] = useState<string>(originalPrompt || "");
    const [numLines, setNumLines] = useState(10);
    const [isMaxInputLengthExceeded, setIsMaxInputLengthExceeded] = useState(false);
    const toasterId = useId("toaster");
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const { dispatchToast } = useToastController(toasterId);
    const notify = (message: string, title: string, intent: "success" | "warning") =>
        dispatchToast(
            <Toast>
                <ToastTitle action={<Link>Undo</Link>}>{title}</ToastTitle>
                <ToastBody subtitle={message}></ToastBody>
            </Toast>,
            { intent }
        );


    // Update numLines every time text changes
    useEffect(() => {
        const lines = question.split('\n').length;
        if (lines < numLines && question.length < maxInputLength) {
            setNumLines(lines);
        } else if (question.length >= maxInputLength) {
            setIsMaxInputLengthExceeded(true);
        } else {
            setIsMaxInputLengthExceeded(false);
            setNumLines(lines);
        }
    }, [question, numLines, maxInputLength]);

    // handle send
    const sendQuestion = () => {
        if (disabled || !question.trim() || isMaxInputLengthExceeded) {
            return;
        }
        onSend(question);
        if (clearOnSend) {
            clearChatInput();
        }
    };

    const handleFollowupQuestion = (followup: string) => {
        onSend(followup)
        if (clearOnSend) {
            clearChatInput();
        }
    }

    // handle keypress - shift enter to improve question or enter to submit the question
    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === "Enter" && !ev.shiftKey && !isMaxInputLengthExceeded) {
            ev.preventDefault();
            sendQuestion();
            setInputLength(0); // reset input length
        } else if (ev.key === "I" && ev.ctrlKey) {
            ev.preventDefault();
            improveQuestion(question);
        }
    };

    // New function to improve question
    const improveQuestion = async (question: string) => {
        const access_token = await getAccessTokenSilently();
        try {
            const response = await improveQuestionApi(question, access_token);
            if (response) {
                setQuestion(response);
            }
            notify('Question improved', 'Success!', 'success');
        }
        catch (error) {
            console.error(error);
            notify('Improve Failed', 'Error', 'warning');
        }
    }

    const onQuestionChange = (ev: React.ChangeEvent<HTMLTextAreaElement>, data: TextareaOnChangeData) => {
        const newValue = data.value || "";
        const textarea = textareaRef.current;
        if (newValue.length === 0) {
            clearChatInput();
        } else if (newValue.length <= maxInputLength) {
            setQuestion(newValue);
            if (setChatboxInput) {
                setChatboxInput(newValue);
            }
            setInputLength(newValue.length); // length of input
            if (textarea) {
                // Reset textarea height to auto to correctly calculate the scrollHeight
                textarea.style.height = 'auto';
                // Set the height based on the scrollHeight
                textarea.style.height = `${textarea.scrollHeight}px`;
            }
        }
    };

    useEffect(() => {
        if (chatBoxValue) {
            setQuestion(chatBoxValue);
            if (setChatboxInput) {
                setChatboxInput(chatBoxValue);
            }
        }
    }, [chatBoxValue])


    //clear the chat
    useEffect(() => {
        clearChatInput();
    }, [clearChatBox]);

    // clear input box
    function clearChatInput() {
        if (setChatboxInput) {
            setChatboxInput('');
        }
        setQuestion(""); // clear input
        setNumLines(6);
        const textarea = textareaRef.current;
        if (textarea) {
            // Reset textarea height to auto to correctly calculate the scrollHeight
            textarea.style.height = '100PX';
        }
    }

    const sendQuestionDisabled = disabled || !question.trim() || isMaxInputLengthExceeded;



    return (
        <div className={styles.questionInputContainer}>

            <Textarea
                disabled={disabled}
                aria-label="textarea"
                ref={textareaRef}
                className={styles.questionInputTextArea}
                placeholder={placeholder}
                value={question}
                onChange={onQuestionChange}
                onKeyDown={onEnterPress}
                maxLength={maxInputLength}
                appearance="filled-darker"
                resize="vertical"
                spellCheck
                rows={6}
                data-tg-tour="Here you can input the text you would like to use as a query..."
                data-tg-title="Your query"
                data-tg-group="tourSubmitQuery"
                data-tg-order="2"
            />
            {followupQuestions && !disabled ? (
                <>
                    <Stack.Item
                        data-tg-tour="Blue Edge AI can suggest potential follow-up questions you may want to ask. If you would like to use one, simply click on it..."
                        data-tg-title="Suggested follow-up"
                        data-tg-group="tourSubmitQuery"
                        data-tg-order="4"
                    >
                        <Stack horizontal className={styles.followupQuestionsList} wrap tokens={{ childrenGap: 6 }}>

                            {followupQuestions.map((x, i) => {
                                return (
                                    <a key={i} className={styles.followupQuestion} title={x} onClick={() => handleFollowupQuestion(x)}>
                                        {`${x}`}
                                    </a>
                                );
                            })}
                        </Stack>
                    </Stack.Item>
                </>
            ) : ''}
            <div className={styles.promptQualityIndicator}
                data-tg-tour="When asking questions to the AI, the longer a query, the better the result. This indicator will help you gauge if you should be asking a longer query by adding some context..."
                data-tg-title="Quality?"
                data-tg-group="tourSubmitQuery"
                data-tg-order="3">
                <ProgressBar
                    value={question.length < 100 ? question.length : 100}
                    color={
                        question.length < 30 ? 'error' :
                            question.length < 70 ? 'warning' :
                                'success'
                    }
                    thickness="large"
                    max={100}
                />
                {
                    question.length < 30 ? <p><strong>Low Quality:</strong> A longer prompt with more context will provide better results</p> :
                        question.length < 70 ? <p><strong>Medium Quality:</strong> Consider including important keywords and requirements</p> :
                            <p><strong>Good Quality:</strong> The more detail you add to a prompt, the better results you will get.</p>
                }
                {question.length}/{maxInputLength}
            </div >
            <div className={styles.chatSubmitButtons}
                data-tg-tour="Once you are happy with your query, use the Submit button to activate the AI."
                data-tg-title="Ready, Set..."
                data-tg-group="tourSubmitQuery"
                data-tg-order="5"
            >
                <Button disabled={question.length < 1} icon={<ChatDismissRegular />} onClick={() => clearChatInput()} appearance="secondary">Clear</Button>

                <Button
                    appearance='primary'
                    onClick={() => sendQuestion()}
                    icon={<ChevronRightRegular />}
                    disabled={sendQuestionDisabled}
                    iconPosition="after"
                >
                    {isMaxInputLengthExceeded ? "Character limit reached" : "Submit query"}
                </Button>
            </div>
        </div>
    );
};
